/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useState, useEffect } from 'react';

import {
  Col,
  Form,
  Button,
  Dropdown,
  Menu,
  Space,
  Empty,
  Spin
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

// Redux
import { useSelector } from 'react-redux'

// Image
import nodataImage from '../../../../Assets/Images/nodata.jpg'

import './style.sass';

const Contents = () => {
  const [usersClasses, setUsersClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [clickToggle, setClickToggle] = useState(false);
  const [slectedClassDriveFolderId, setSlectedClassDriveFolderId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const classData = useSelector((state) => state.classData.classData);
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('yasalankalmsuser'));
    if (!user) {
      window.location.href = '/signin';
    }
    // set users classes
    if (userData?.role !== 'student') {
      setUsersClasses(classData);
      setSelectedClass(classData[0]);
    } else {
      const classesOfStudent = userData?.classes;
      const classes = classData?.filter((classData) => classesOfStudent?.includes(classData?.id));
      setUsersClasses(classes);
      setSelectedClass(classes[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedClass?.gdriveurl) {
      const driveFolderId = selectedClass.gdriveurl.split('/')[5].split('?')[0];
      setSlectedClassDriveFolderId(driveFolderId);
    }
  }, [selectedClass]);



  return (
    <div className='contents-page'>
      <Col span={24} className='contents-page-header'>
        <Form.Item>
          <Dropdown
            overlay={
              <Menu onClick={null}>
                {usersClasses?.map((cls) => (
                  <Menu.Item
                    key={cls.id}
                    onClick={() => {
                      setSelectedClass(cls);
                      setClickToggle(!clickToggle);
                      setSlectedClassDriveFolderId(null);
                      setIsLoading(true);
                    }}
                  >
                    {cls.name}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                {selectedClass ? selectedClass.name : 'Select Your class'}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Form.Item>

      </Col>
      <Col span={24} className='contents-page-body'>
        {slectedClassDriveFolderId ?
          <Spin
            spinning={isLoading}
            tip="Please wait..."
          >
            <iframe
              className='drive-iframe'
              title="Google Drive Folder"
              src={`https://drive.google.com/embeddedfolderview?id=${slectedClassDriveFolderId}#grid`}
              // check is loading 
              onLoad={() => {
                setIsLoading(false);
              }}
            >
            </iframe>
          </Spin>
          :
          <Empty
            image={nodataImage}
            imageStyle={{
              height: 'calc(100vh - 80vh)',
            }}
            description={
              <p
                style={{
                  color: '#A4A4A4',
                  fontSize: '20px',
                  fontWeight: '300',
                  marginTop: '30px',
                }}
              >
                This class has no contents yet.
              </p>
            }
          />
        }
      </Col>
    </div>
  )
}

export default Contents