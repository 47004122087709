/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { createSlice } from '@reduxjs/toolkit';

export const classDataSlice = createSlice({
    name: 'classData',
    initialState: {
        classData: {},
    },
    reducers: {
        addClassData: (state, action) => {
            state.classData = action.payload;
        }
    },
});

export const { addClassData } = classDataSlice.actions;

export default classDataSlice.reducer;
