/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useEffect, useState } from 'react';
import { useDoubleTap } from 'use-double-tap';
import youtube from '../../../../../youtube';

import { Button, Col, Layout, Row, Slider, notification, Popover, FloatButton, Spin } from 'antd';
import {
    CaretRightOutlined,
    PauseOutlined,
    CompressOutlined,
    ExpandOutlined,
    SettingOutlined,
    CaretUpOutlined,
    BackwardOutlined,
    ForwardOutlined
} from '@ant-design/icons';
import { BsVolumeUp, BsVolumeMute } from "react-icons/bs";

import './style.sass';

// Images
import Lottie from 'react-lottie';
import RotateJson from './rotate.json'

const { Header, Footer, Content } = Layout;

const YoutubeVideoComponent = () => {
    const [player, setPlayer] = useState(null);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(5);
    const [seeker, setSeeker] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [playerState, setPlayerState] = useState(null);
    const [selectQuality, setSelectQuality] = useState('auto');
    const [videoData, setVideoData] = useState(null);
    const [selectedSpeed, setSelectedSpeed] = useState(1);

    const [isSkipLeft, setIsSkipLeft] = useState(false);
    const [isSkipRight, setIsSkipRight] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('yasalankalmsuser'));
        if (!user) {
            window.location.href = '/signin';
        }

        const data = JSON.parse(sessionStorage.getItem('yasalanka-videoData'));
        setVideoData(data);

        if (data) {
            const tag = document.createElement('script');
            tag.src = 'https://youtube.com/iframe_api';
            tag.id = 'youtubeScript';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = () => onYouTubeIframeAPIReady(data.snippet.resourceId.videoId);
            getVideoDetails(data.snippet.resourceId.videoId);
        }

        // if screen size is less than 900px show pop up message to rotate screen
        if (window.innerWidth < 900) {
            openNotification();
        }
    }, []);

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: RotateJson,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const openNotification = () => {
        api.open({
            description:
                <div className='notification-rotate'>
                    <Lottie
                        options={lottieOptions}
                        height={70}
                        width={70}
                    />
                    <p>
                        Please rotate your screen for better experience.
                    </p>
                </div>,
            placement: 'top',
        });
    };

    const getVideoDetails = (videoId) => {
        youtube
            .get('/videos', {
                params: {
                    part: 'contentDetails',
                    id: videoId,
                },
            })
            .then((response) => {
                const duration = response.data.items[0].contentDetails.duration;
                // convert ISO 8601 duration to seconds using moment.js
                const moment = require('moment');
                const momentDuration = moment.duration(duration);
                const durationInSeconds = momentDuration.asSeconds();
                setDuration(durationInSeconds);
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }

    useEffect(() => {
        // Periodically update the seeker based on video progress
        const intervalId = setInterval(() => {
            if (player && player.getCurrentTime) {
                const currentTime = player.getCurrentTime();
                setSeeker(currentTime);
            }
        }, 1000); // Update every 1 second

        return () => clearInterval(intervalId);
    }, [player]);

    const onYouTubeIframeAPIReady = (videoId) => {
        setPlayer(
            new window.YT.Player('player-div', {
                width: "100px",
                videoId: videoId,
                playerVars: {
                    autoplay: 0,
                    controls: 0,
                    rel: 0,
                    fs: 0,
                    showinfo: 0,
                    modestbranding: 1,
                },
                events: {
                    onStateChange: onPlayerStateChange,
                },
            })
        );
    };

    const onPlayerStateChange = (event) => {
        switch (event.data) {
            case window.YT.PlayerState.PLAYING:
                setPlayerState("PLAYING");
                break;
            case window.YT.PlayerState.PAUSED:
                setPlayerState("PAUSED");
                break;
            case window.YT.PlayerState.BUFFERING:
                setPlayerState("BUFFERING");
                break;
            case window.YT.PlayerState.ENDED:
                setPlayerState("ENDED");
                setIsPlaying(false);
                break;
            default:
                break;
        }
    };

    const chnagePlayPause = () => {
        if (player) {
            if (isPlaying) {
                player.pauseVideo();
                setIsPlaying(false);
            } else {
                player.playVideo();
                setIsPlaying(true);
            }
        }
    };

    const handleVolumeChange = (value) => {
        setVolume(value);
        if (player) player.setVolume(value);
    };

    const handleSeekChange = (value) => {
        setSeeker(value);
        if (player) player.seekTo(value);
    };

    const chnageVideoQuality = (value) => {
        if (player && (playerState === 'PLAYING' || playerState === 'BUFFERING')) {
            player.setPlaybackQuality(value);
            setSelectQuality(value)
        }
    }

    const handleFullScreen = () => {
        const header = document.getElementsByClassName('video-header');
        const footer = document.getElementsByClassName('video-footer');
        const playerDiv = document.getElementsByClassName('video-player-div');
        if (player) {
            if (isFullScreen) {
                // hide header
                header[0].classList.remove('hide-header');
                footer[0].classList.remove('hide-footer');
                playerDiv[0].classList.remove('max-player');
                setIsFullScreen(false);
            } else {
                // TODO - Exit full screen
                setIsFullScreen(true);
                footer[0].classList.remove('show-control-footer');
                playerDiv[0].classList.remove('show-control-player');

                header[0].classList.add('hide-header');
                footer[0].classList.add('hide-footer');
                playerDiv[0].classList.add('max-player');
            }
        }
    }

    const showControls = () => {
        if (player) {
            if (isFullScreen) {
                const footer = document.getElementsByClassName('video-footer');
                const playerDiv = document.getElementsByClassName('video-player-div');

                footer[0].classList.add('show-control-footer');
                playerDiv[0].classList.add('show-control-player');

                setIsFullScreen(false);
            }
        }
    }

    const videoQualities = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
            }}
        >
            <Button
                type={
                    selectQuality === 'small' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('small');
                }}

            >
                Small
            </Button>
            <Button
                type={
                    selectQuality === 'medium' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('medium');
                }}
            >
                Medium
            </Button>
            <Button
                type={
                    selectQuality === 'large' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('large');
                }}
            >
                Large
            </Button>
            <Button
                type={
                    selectQuality === 'hd720' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('hd720');
                }}
            >
                HD 720
            </Button>
            <Button
                type={
                    selectQuality === 'hd1080' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('hd1080');
                }}

            >
                HD 1080
            </Button>
            <Button
                type={
                    selectQuality === 'auto' ? 'primary' : 'text'
                }
                onClick={() => {
                    chnageVideoQuality('auto');
                }}
            >
                Auto
            </Button>
        </div>
    )

    const playbackSpeed = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
            }}
        >
            <Button
                type={
                    selectedSpeed === 0.25 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(0.25);
                        setSelectedSpeed(0.25);
                    }
                }}
            >
                0.25
            </Button>
            <Button
                type={
                    selectedSpeed === 0.5 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(0.5);
                        setSelectedSpeed(0.5);
                    }
                }}
            >
                0.5
            </Button>
            <Button
                type={
                    selectedSpeed === 0.75 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(0.75);
                        setSelectedSpeed(0.75);
                    }
                }}
            >
                0.75
            </Button>
            <Button
                type={
                    selectedSpeed === 1 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(1);
                        setSelectedSpeed(1);
                    }
                }}
            >
                1
            </Button>
            <Button
                type={
                    selectedSpeed === 1.25 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(1.25);
                        setSelectedSpeed(1.25);
                    }
                }}
            >
                1.25
            </Button>
            <Button
                type={
                    selectedSpeed === 1.5 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(1.5);
                        setSelectedSpeed(1.5);
                    }
                }}
            >
                1.5
            </Button>
            <Button
                type={
                    selectedSpeed === 1.75 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(1.75);
                        setSelectedSpeed(1.75);
                    }
                }}
            >
                1.75
            </Button>
            <Button
                type={
                    selectedSpeed === 2 ? 'primary' : 'text'
                }
                onClick={() => {
                    if (player) {
                        player.setPlaybackRate(2);
                        setSelectedSpeed(2);
                    }
                }}
            >
                2
            </Button>
        </div>
    )

    const bindLeft = useDoubleTap((event) => {
        console.log('Double tapped');
        if (player) {
            player.seekTo(seeker - 10);
            setIsPlaying(true);
            setIsSkipLeft(true);
            setTimeout(() => {
                setIsSkipLeft(false);
            }, 2000);
        }
    });

    const bindRight = useDoubleTap((event) => {
        console.log('Double tapped');
        if (player) {
            player.seekTo(seeker + 10);
            setIsPlaying(true);
            setIsSkipRight(true);
            setTimeout(() => {
                setIsSkipRight(false);
            }, 2000);
        }
    });


    return (
        <Spin
            spinning={!player}
            tip="Please wait..."
        >
            {contextHolder}
            <Layout
                className='video-frame'
                style={{
                    width: '100vw',
                    height: '100vh',
                }}
            >
                {isFullScreen &&
                    < FloatButton
                        tooltip={<div>Show Controls</div>}
                        icon={<CaretUpOutlined />}
                        type="primary"
                        onClick={showControls} />}
                <Header
                    className='video-header'
                >
                    {videoData &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '100%',
                                fontSize: '20px',
                                color: '#fff',
                            }}
                        >
                            <p>
                                {videoData.snippet.title}
                            </p>

                            <p className='channel-title'>
                                {videoData.snippet.channelTitle}
                            </p>
                        </div>}
                </Header>
                <Content
                    style={{
                        flex: 'none',
                    }}
                >
                    <div className='skip-container'>
                        <div className='skip-left'
                            {...bindLeft}
                        >
                            {isSkipLeft && <div className='skip-left-text'>
                                <BackwardOutlined /> -10 seconds
                            </div>}
                        </div>
                        <div className='skip-center'
                            onClick={chnagePlayPause}
                        >
                            {!isPlaying && <div className='skip-center-text'>
                                <Col className='play-pause-new'>
                                    <Button
                                        type='primary'
                                        onClick={chnagePlayPause}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                        }}
                                    >
                                        {isPlaying ?
                                            <PauseOutlined style={{
                                                fontSize: '20px',
                                            }} />
                                            :
                                            <CaretRightOutlined style={{
                                                fontSize: '20px',
                                            }} />}
                                    </Button>
                                </Col>
                            </div>
                            }
                        </div>
                        <div className='skip-right'
                            {...bindRight}
                        >
                            {isSkipRight && <div className='skip-right-text'>
                                +10 seconds  <ForwardOutlined />
                            </div>}
                        </div>
                    </div>
                    <div
                        className='video-player-div'
                        id="player-div"
                        style={{
                            pointerEvents: 'none',
                            mouseEvents: 'none',
                            touchAction: 'none',
                            WebkitTapHighlightColor: 'transparent',
                            outline: 'none',
                            WebkitUserSelect: 'none',
                            MozUserSelect: 'none',
                            msUserSelect: 'none',
                            userSelect: 'none',
                            WebkitTouchCallout: 'none',
                            MozTouchCallout: 'none',
                            msTouchCallout: 'none',
                            touchCallout: 'none',
                            WebkitUserModify: 'none',
                            MozUserModify: 'none',
                            msUserModify: 'none',
                            userModify: 'none',
                        }}
                    ></div>
                </Content>

                <Footer className='video-footer'>
                    {duration > 0 &&
                        <Slider
                            className='seeker-new'
                            min={0}
                            max={duration}
                            value={seeker}
                            onChange={handleSeekChange}
                            marks={{
                                0: `${Math.floor(seeker / 60)}:${Math.floor(seeker % 60) < 10 ? '0' : ''}${Math.floor(seeker % 60)}`,

                                [duration]: `${Math.floor(duration / 60)}:${Math.floor(duration % 60) < 10 ? '0' : ''}${Math.floor(duration % 60)}`,
                            }}
                            tipFormatter={(value) => {
                                const minutes = Math.floor(value / 60);
                                const seconds = Math.floor(value % 60);
                                return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                            }}
                        />}

                    <Row
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        className='video-controls-new'
                    >
                        <Col className='volume-controls-new'>
                            <Button
                                type='text'
                                onClick={() => {
                                    if (player) {
                                        if (isMuted) {
                                            player.unMute();
                                            setIsMuted(false);
                                        } else {
                                            player.mute();
                                            setIsMuted(true);
                                        }
                                    }
                                }}
                            >
                                {isMuted ? <BsVolumeMute
                                    style={{
                                        color: '#ff5656',
                                        fontSize: '20px',
                                    }}

                                /> : <BsVolumeUp
                                    style={{
                                        color: '#0062ff',
                                        fontSize: '20px',
                                    }}
                                />}
                            </Button>
                            <Slider
                                style={{
                                    width: '100px',
                                }}
                                min={0}
                                max={100}
                                value={volume}
                                onChange={handleVolumeChange}
                                tipFormatter={(value) => {
                                    return `${value}`;
                                }}
                            />
                        </Col>

                        {/* Play pause */}
                        <Col className='play-pause-new'>
                            <Button
                                type='primary'
                                onClick={chnagePlayPause}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}
                            >
                                {isPlaying ?
                                    <PauseOutlined style={{
                                        fontSize: '20px',
                                    }} />
                                    :
                                    <CaretRightOutlined style={{
                                        fontSize: '20px',
                                    }} />}
                            </Button>
                        </Col>

                        {/* Full Screen */}
                        <Col className='full-screen-new'>
                            <Popover
                                content={playbackSpeed}
                                title="Playback Speed"
                                trigger="hover"
                            >
                                <Button
                                    type='text'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#0062ff',
                                        margin: '0',
                                        padding: '0',
                                    }}
                                >
                                    X {selectedSpeed}
                                    <CaretUpOutlined
                                        style={{
                                            color: '#0062ff',
                                            fontSize: '20px',
                                        }}
                                    />
                                </Button>
                            </Popover>

                            <Button
                                type='text'
                                onClick={handleFullScreen}
                            >
                                {isFullScreen
                                    ? <CompressOutlined style={{
                                        color: '#0062ff',
                                        fontSize: '20px',
                                    }} /> : <ExpandOutlined
                                        style={{
                                            color: '#0062ff',
                                            fontSize: '20px',
                                        }}
                                    />}
                            </Button>

                            <Popover content={videoQualities}
                                title="Video Quality"
                                trigger="hover">
                                <Button
                                    type='text'
                                >
                                    <SettingOutlined
                                        style={{
                                            color: '#0062ff',
                                            fontSize: '20px',
                                        }} />
                                </Button>
                            </Popover>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </Spin>
    );
};

export default YoutubeVideoComponent;