/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, setDoc, collection, addDoc, getDoc, getDocs, updateDoc, doc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCPD8xDHyFg-vsc1l9jsnicQbvPEFdKlyM",
    authDomain: "yasalankalms-ce061.firebaseapp.com",
    projectId: "yasalankalms-ce061",
    storageBucket: "yasalankalms-ce061.appspot.com",
    messagingSenderId: "84983837731",
    appId: "1:84983837731:web:3adb58c58e8d488ca06d96",
    measurementId: "G-CTC8HPRZ48"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);
export { storage, auth, app, db, collection, setDoc, getDoc, addDoc, getFirestore, getDocs, updateDoc, doc };