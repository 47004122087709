/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { configureStore, combineReducers } from '@reduxjs/toolkit'
import videosReducer from './App/Common/Slices/videosSlice'
import classDataReducer from './App/Common/Slices/classDataSlice'
import userDataReducer from './App/Common/Slices/userDataSlice';
import usersSlice from './App/Common/Slices/usersSlice';
import homeDataSlice from './App/Common/Slices/homeDataSlice';

const rootReducer = combineReducers({
    videos: videosReducer,
    classData: classDataReducer,
    userData: userDataReducer,
    usersData: usersSlice,
    homeData: homeDataSlice,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;