/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useEffect, useState } from 'react';
import UsersTable from './UsersTable';

import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver';

// Firebase
import { db, collection, getDocs } from '../../../../firebase';

// Redux
import { useSelector } from 'react-redux'

// Ant Design
import { Button, Modal, Form, Dropdown, Menu, Space, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const Users = () => {
  const userData = useSelector((state) => state.userData.userData);
  const [allUsers, setAllUsers] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const classData = useSelector((state) => state.classData.classData);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('yasalankalmsuser'));
    if (!user) {
      window.location.href = '/signin';
    }
  }, []);

  useEffect(() => {
    // Check if user is not a student
    if (userData?.role === 'student') {
      window.location.href = '/dashboard';
    } else {
      const getUsers = async () => {
        const userList = [];
        try {
          const querySnapshot = await getDocs(collection(db, "users"));

          querySnapshot.forEach((doc) => {
            userList.push(doc.data());
          });
          setAllUsers(userList);
        } catch (error) {
          console.log('Error fetching users:', error);
        }
      };
      if (userData) {
        getUsers();
      }
    }
  }, [userData]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onFinish = () => {
    if (selectedClass) {
      const filteredUsers = filterUsers(allUsers);
      const formattedData = formatData(filteredUsers);
      exportToExcel(formattedData);
      setIsModalOpen(false);
    } else {
      error();
    }
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Please select a class',
    });
  };

  const filterUsers = (users) => {
    const filteredUsers = [];
    users.forEach((user) => {
      if (user.role === 'student') {
        if (user.activeStatus === 'active') {
          user.classes.forEach((cls) => {
            if (cls === selectedClass.id) {
              filteredUsers.push(user);
            }
          });
        }
      }
    });
    return filteredUsers;
  }

  const formatData = (users) => {
    const formattedData = [];
    users.forEach((user) => {
      const addressLines = user.address.split(' ').map((line) => line.trim()).join(', ');

      const data = {
        'Full Name': user.fullname,
        'Index Number': user.indexNumber,
        'Email': user.email,
        'Phone Number': user.contact,
        'Address': addressLines,
      };

      formattedData.push(data);
    });
    return formattedData;
  }

  const exportToExcel = (allUsers) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(allUsers);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Users' + fileExtension);
  }

  return (
    <div className='all-users'>
      {contextHolder}
      <header
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
        className='all-users-header'>
        <Button
          onClick={showModal}
          type='primary'
        >
          Export to Excel
        </Button>
      </header>
      <UsersTable data={allUsers} />

      <Modal
        title="Select class and click 'Export'"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={400}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0',
            margin: '20px 0 0 0',
          }}
        >
          <Form.Item>
            <Dropdown
              overlay={
                <Menu >
                  {classData?.map((cls) => (
                    <Menu.Item
                      key={cls.id}
                      onClick={() => {
                        setSelectedClass(cls);
                      }}
                    >
                      {cls.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedClass ? selectedClass.name : 'Select Your class'}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Export
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
