/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useEffect, useRef, useState } from 'react';

import {
  Row,
  Col,
  Statistic,
  Tabs,
  Badge,
  Tag,
  Carousel,
  Button,
  List,
  Modal,
  Form,
  Input,
  notification,
  Spin,
  Checkbox,
  Skeleton,
  DatePicker,
  Switch,
  Tooltip
} from 'antd';
import { UsergroupAddOutlined, BulbOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import CountUp from 'react-countup';
import VirtualList from 'rc-virtual-list';
import dayjs from 'dayjs';

import './style.sass';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setHomeData } from '../../../Common/Slices/homeDataSlice';

// Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css';

// Firebase
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

// Images
import Lottie from 'react-lottie';
import StarsJson from './stars.json'

import Dialog from './Assets/dialog.jpg'
import Hutch from './Assets/hutch.jpg'
import Mobitel from './Assets/mobitel.jpg'
import Slt from './Assets/slt.jpg'

const { Countdown } = Statistic;

const formatter = (value) => <CountUp end={value} separator="," />;

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};


const Home = () => {
  const userData = useSelector((state) => state.userData.userData);
  const usersData = useSelector((state) => state.usersData.usersData);
  const classData = useSelector((state) => state.classData.classData);
  const [activeUsers, setActiveUsers] = useState(null);
  const [newUsers, setNewUsers] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const [overView, setOverView] = useState(null);

  const [openVieoModal, setOpenVideoModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const [openOverviewModal, setOverviewVideoModal] = useState(false);
  const [openExamCountdownModal, setExamCountdownModal] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const coundownFormRef = useRef(null);


  const homeData = useSelector((state) => state.homeData.homeData);
  const dispatch = useDispatch();

  useEffect(() => {
    // filter all students from usersData
    if (usersData.length > 0) {
      const studentData = usersData.filter((user) => user.role === 'student');
      const activeStudentData = studentData.filter((user) => user.activeStatus === 'active');
      setActiveUsers(activeStudentData.length);
      const pendingStudentData = studentData.filter((user) => user.activeStatus === 'pending');
      setNewUsers(pendingStudentData.length);
    }
  }, [userData, usersData]);

  useEffect(() => {
    const getHomeData = async () => {
      const dataList = [];
      try {
        const querySnapshot = await getDocs(collection(db, "dashboardContents"));

        querySnapshot.forEach((doc) => {
          dataList.push(doc.data());
        });
        dispatch(setHomeData(dataList[0]));
      } catch (error) {
        console.log('Error fetching users:', error);
      }
    };
    if (!homeData) {
      getHomeData();
    }
  }, []);

  useEffect(() => {
    const vid = homeData?.videoUrl?.split('https://youtu.be/')[1];
    setVideoId(vid);
    const items = [
      {
        label: 'Overview',
        key: '1',
        children:
          <div className='overview'>
            {userData?.role !== 'student' && <div className='button-container'>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={handleChangeOverview}
              >
                Edit Overview
              </Button>
            </div>}
            <ReactQuill
              className='quill'
              readOnly
              theme="bubble"
              value={overView}
              style={{
                height: userData?.role !== 'student' ? '240px' : '280px',
              }}
            />
          </div>
      },
      {
        label: 'Benefits',
        key: '2',
        children:
          <>
            <Tag icon={<BulbOutlined />} color="blue">Easy to use, user friendly interface.</Tag>
            <Tag icon={<BulbOutlined />} color="blue">Get updates about the class instantly.</Tag>
            <Tag icon={<BulbOutlined />} color="blue">Unlimited number of video playback availability.</Tag>
            <Tag icon={<BulbOutlined />} color="blue">Availability of budget friendly data packages.</Tag>
            <Tag icon={<BulbOutlined />} color="blue">Unlimited access to course content materials.</Tag>
            <Tag icon={<BulbOutlined />} color="blue">Always up-to-date course contents.</Tag>
          </>
      },
      {
        label: 'Data Packages',
        key: '3',
        children:
          <div>
            <Carousel
              autoplay
            >
              <div>
                <span >
                  <img src={Mobitel} alt="1" border="0" />
                </span>
              </div>
              <div>
                <span>
                  <img src={Dialog} alt="1" border="0" />
                </span>
              </div>
              <div>
                <span>
                  <img src={Hutch} alt="1" border="0" />
                </span>
              </div>
              <div>
                <span>
                  <img src={Slt} alt="1" border="0" />
                </span>
              </div>
            </Carousel>
          </div>,
      },
    ]
    setAllItems(items);
    setOverView(homeData?.overview);
  }, [homeData, overView]);

  // edit video
  //___________________________________________________________________________
  const handleChangeVideo = () => {
    setOpenVideoModal(true);
  }
  const handleVideoOk = (values) => {
    handleVidoeEdit(values);
  };
  const handleVideoCancel = () => {
    setOpenVideoModal(false);
  };

  const handleVidoeEdit = async (values) => {
    if (!homeData) return;
    try {
      setIsLoading(true);
      const docRef = doc(db, "dashboardContents", homeData?.id);
      await setDoc(docRef, {
        ...homeData,
        videoUrl: values.videoUrl,
      });

      setOpenVideoModal(false);
      openNotificationWithIcon('success');
      setIsLoading(false);
    } catch (error) {
      openNotificationWithIcon('error');
      setIsLoading(false);
      console.log("🚀 ~ file: index.js:85 ~ handleVidoeEdit ~ error", error)
    }
  }
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: {
        success:
          'Video updated successfully'
        , error: 'Error'
      }[type],
      description: {
        success: "Video updated successfully, It will be reflected on the home page."
        , error: 'Something went wrong'
      }[type],
    });
  };

  // edit overview
  //___________________________________________________________________________

  const handleChangeOverview = () => {
    setOverviewVideoModal(true);
  }
  const handleOverviewOk = (values) => {
    if (values?.overview) {
      if (values?.overview?.length > 0 && values?.overview !== overView) {
        handleOverviewEdit(values);
      }
    } else {
      openNotificationWithIconOverview('error');
    }
  };
  const handleOverviewCancel = () => {
    setOverviewVideoModal(false);
  };

  const handleOverviewEdit = async (values) => {
    if (!homeData) return;
    try {
      setIsLoading(true);
      const docRef = doc(db, "dashboardContents", homeData?.id);
      await setDoc(docRef, {
        ...homeData,
        overview: values.overview,
      });

      setOverviewVideoModal(false);
      openNotificationWithIconOverview('success');
      setIsLoading(false);
    } catch (error) {

      if (error.message === 'The value of property "overview" is longer than 1048487 bytes.') {
        openNotificationWithIconOverviewTooLarge('error');
      } else {
        openNotificationWithIconOverview('error');
      }
      setIsLoading(false);
    }
  }
  const openNotificationWithIconOverview = (type) => {
    api[type]({
      message: {
        success:
          'Overview updated successfully'
        , error: 'Error'
      }[type],
      description: {
        success: "Overview updated successfully, It will be reflected on the home page."
        , error: 'Something went wrong'
      }[type],
    });
  };

  const openNotificationWithIconOverviewTooLarge = (type) => {
    api[type]({
      message: {
        success:
          'Overview updated successfully'
        , error: 'Error'
      }[type],
      description: {
        success: "Overview updated successfully, It will be reflected on the home page."
        , error: 'Your are trying to add too much content. Please reduce the content and try again.'
      }[type],
    });
  }

  // edit exam countdown___________________________________________________________________________

  const handleEditCountdownCancel = () => {
    setExamCountdownModal(false);
  };

  const handleEditCountdownOk = (values) => {
    const examDate = values.examDate.$d;
    const timeStampe = examDate.getTime();
    values.examDate = timeStampe;
    handleEditCountdown(values);
  }

  const handleEditCountdown = async (values) => {
    if (!homeData) return;
    try {
      setIsLoading(true);
      const docRef = doc(db, "dashboardContents", homeData?.id);
      await setDoc(docRef, {
        ...homeData,
        examDate: values.examDate,
        isShowCountdown: values.showCountdown,
        countdownShowClasses: values.classes,
      });

      setExamCountdownModal(false);
      openNotificationWithIconCountdown('success');
      setIsLoading(false);
    } catch (error) {
      openNotificationWithIconCountdown('error');
      setIsLoading(false);
      console.log("🚀 ~ file: index.js:85 ~ handleVidoeEdit ~ error", error)
    }
  }
  const handleChnageExamCountdown = () => {
    setExamCountdownModal(true);
  }

  const setCountdownFormValues = () => {
    if (homeData && coundownFormRef.current) {
      const classes = homeData?.countdownShowClasses;
      const showCountdown = homeData?.isShowCountdown;
      const examDate = homeData?.examDate ? dayjs(homeData?.examDate) : dayjs().add(1, 'month');

      coundownFormRef.current.setFieldsValue({
        classes,
        examDate,
        showCountdown,
      });
    }
  }

  useEffect(() => {
    if (homeData) {
      setCountdownFormValues();
    }
  }, [openExamCountdownModal]);

  const openNotificationWithIconCountdown = (type) => {
    api[type]({
      message: {
        success:
          'Exam countdown updated successfully'
        , error: 'Error'
      }[type],
      description: {
        success: "Exam countdown updated successfully, It will be reflected on the home page."
        , error: 'Something went wrong'
      }[type],
    });
  };

  useEffect(() => {
    if (homeData) {
      if (homeData?.isShowCountdown) {
        if (userData?.role !== 'student') {
          setShowCountdown(true);
        }
        else {
          const countdownShowClasses = homeData?.countdownShowClasses;
          const userClass = userData?.classes
          if (countdownShowClasses?.length > 0 && userClass?.length > 0) {
            const isShow = countdownShowClasses.some((r) => userClass.includes(r));
            setShowCountdown(isShow);
          }
        }
      }
    }
  }, [homeData, userData]);


  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: StarsJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      {homeData ?
        <div className='dashboard-home'>
          {contextHolder}
          {/* Video Update modal */}
          <Modal title="Edit Video"
            open={openVieoModal}
            footer={[]}
            onCancel={handleVideoCancel}
          >
            <Spin spinning={isLoading}>
              <Form
                layout='vertical'
                onFinish={handleVideoOk}
              >
                <Form.Item
                  label="Video URL"
                  name="videoUrl"
                  rules={[
                    {
                      required: true,
                      message: 'Please input video url!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0px',
                  margin: '0px',
                }}>
                  <Button
                    style={{ marginRight: '10px' }}
                    type="default"
                    onClick={handleVideoCancel}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>


          {/* Edit Overview */}
          <Modal title="Edit Overview"
            open={openOverviewModal}
            footer={[]}
            onCancel={handleOverviewCancel}
            className='overview-modal'
          >
            <Spin spinning={isLoading}>
              <Form
                layout='vertical'
                onFinish={handleOverviewOk}
                style={{
                  height: '500px',
                }}
              >
                <Form.Item
                  label="Overview"
                  name="overview"
                  rules={[
                    {
                      required: true,
                      message: 'Please input overview!',
                    },
                  ]}
                  style={{
                    height: '460px',
                  }}
                >
                  <ReactQuill
                    modules={modules}
                    theme="snow"
                    placeholder="The content starts here..."
                    style={{
                      height: '360px',
                    }}
                  />
                </Form.Item>

                <Form.Item style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0px',
                  margin: '10px 0 0 0',
                }}>
                  <Button
                    style={{ marginRight: '10px' }}
                    type="default"
                    onClick={handleOverviewCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>

          {/* Edit Exam countdown */}
          <Modal
            title="Edit exam countdown"
            open={openExamCountdownModal}
            footer={[]}
            onCancel={handleEditCountdownCancel}
            className='exam-countdown-modal'
          >

            <Spin spinning={isLoading}>
              <Form
                layout='vertical'
                onFinish={handleEditCountdownOk}
                ref={coundownFormRef}
              >
                <Form.Item
                  className="form-bottom"
                  label="Select classes to show countdown"
                  name="classes"
                >
                  <Checkbox.Group
                    style={{
                      width: '100%',
                      margin: '0 0 0 1em',
                    }}
                  >
                    <Row span={24} gutter={20}>
                      {classData.length > 0 ? classData?.map((classItem) => (
                        <Col span={8} key={classItem.id}>
                          <Checkbox
                            checked={homeData?.countdownShowClasses?.includes(classItem.id)}
                            value={classItem.id}>
                            {classItem.name}
                          </Checkbox>
                        </Col>
                      ))
                        :
                        <Skeleton
                          active
                          paragraph={{ rows: 3 }}
                          title={false}
                          size="small"
                        />}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>

                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '30px',
                  }}
                >
                  <Col >
                    <Form.Item
                      label="Exam date"
                      name="examDate"
                      rules={[
                        {
                          required: true,
                          message: 'Please input exam date!',
                        },
                      ]}
                    >
                      <DatePicker
                        style={{
                          width: '200px',
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Show countdown"
                      name="showCountdown"
                    >
                      <Switch
                        defaultChecked={homeData?.isShowCountdown}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '0px',
                  margin: '10px 0 0 0',
                }}>
                  <Tooltip title="Reload form values">
                    <Button
                      style={{ marginRight: '10px' }}
                      type="default"
                      onClick={setCountdownFormValues}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>

                  <Button
                    style={{ marginRight: '10px' }}
                    type="default"
                    onClick={handleEditCountdownCancel}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>

          <Row className='row-1'>
            <Col
              className='col-1'
              flex="2 1 200px">
              <div className='hello-texts'>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
                >
                  <h1>Hello {userData?.role !== 'student' ? 'Master' : userData?.fname}</h1>
                  <h3>{userData?.indexNumber}</h3>
                </div>
                <h2>
                  {
                    new Date().getHours()
                      < 12 ? 'Good Morning!'
                      : new Date().getHours()
                        < 18 ? 'Good Afternoon!'
                        : 'Good Evening!'
                  }
                </h2>
              </div>
              <div className='stars'>
                <Lottie
                  options={lottieOptions}
                  width='100%'
                  height='140px'
                />
              </div>


              {userData?.role !== 'student' ?
                <div className='users-info'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h5>
                    <Statistic
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                      }}
                      title="Active Users"
                      value={activeUsers}
                      formatter={formatter}
                    />
                  </h5>
                  <div>
                    {
                      newUsers > 0
                      &&
                      <Badge count="+" size='large' offset={[-5, 0]}>
                        <Tag
                          type="primary"
                          icon={<UsergroupAddOutlined />}
                          color='blue'
                        >
                          {newUsers} New Users
                        </Tag>
                      </Badge>
                    }
                  </div>
                </div>
                :
                <div className='users-info'>
                  <h5 style={{
                    marginTop: '20px',
                  }}>
                    How are you doing today?
                  </h5>
                </div>
              }

              <div className='exam-countdown'>
                {
                  showCountdown ?
                    <h5>
                      <p>Your A/L exam is in</p>
                      <Countdown
                        value={
                          homeData?.examDate
                            ? homeData?.examDate
                            : dayjs().add(1, 'month').valueOf()
                        }
                        format='D [days]'
                      />
                    </h5>
                    :
                    <h4>
                      <p>
                        {
                          userData?.role !== 'student' ?
                            'Exam countdown has been disabled.'
                            :
                            'Dream big, work hard and make it happen!'
                        }
                      </p>
                    </h4>
                }

                {
                  userData?.role !== 'student' &&
                  <Button
                    type="primary"
                    onClick={
                      () => {
                        setCountdownFormValues();
                        handleChnageExamCountdown();
                      }
                    }
                  >
                    <EditOutlined />  Edit
                  </Button>}
              </div>
            </Col>

            <Col
              flex="3 1 300px"
              className='col-2'
            >
              <Tabs
                defaultActiveKey="1"
                centered
                items={allItems}
              />
            </Col>
          </Row>
          <Row className='row-2'>
            <Col flex="4 1 200px">
              <div style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}>
                {userData?.role !== 'student' && <Button
                  type="primary"
                  style={{
                    marginBottom: '10px',
                  }}
                  icon={<EditOutlined />}
                  onClick={handleChangeVideo}
                >
                  Edit video
                </Button>}
              </div>
              {videoId &&
                <iframe
                  width='100%'
                  height={userData?.role === 'student' ? '100%' : '85%'}
                  src={`https://www.youtube-nocookie.com/embed/${videoId}&amp;`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                >
                </iframe>}
            </Col>
            <Col className='col-2' flex="1 1 300px">
              <h1>All available classes</h1>
              {classData.length > 0 &&
                <List>
                  <VirtualList
                    data={classData}
                    height='300px'
                    itemHeight={47}
                    itemKey="id"
                  >
                    {(item) => (
                      <List.Item key={item?.id}>
                        <List.Item.Meta
                          title={item?.name}
                        />
                        {userData?.role !== 'student' && <div>{item?.studentsCount}</div>}
                      </List.Item>
                    )}
                  </VirtualList>
                </List>}
            </Col>
          </Row>
        </div>
        :
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
          }}
          className='home-loading'
        >
          <Spin size='large' />
        </div>
      }
    </>
  )
}

export default Home