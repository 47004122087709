/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useState } from 'react'

import {
  Button,
  Tooltip,
  Modal
} from 'antd'
import {
  EnvironmentTwoTone,
  PhoneTwoTone,
  MailTwoTone,
  WhatsAppOutlined,
  PhoneOutlined
} from '@ant-design/icons';

// carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Reveal
import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles.sass'
import './Slider.css'
import './button.css'


import HomeHeroImg from './Assets/homeHero.png'
import LearnfromAnyware from './Assets/LearnfromAnyware.png'
import Videos from './Assets/video.png'
import Tutes from './Assets/tutes.png'
import Cost from './Assets/cost.png'
import Exam from './Assets/Exam.png'
import Contact from './Assets/contact.jpg'
import Launching from './Assets/launching.png'

import Student1 from './Assets/Students/student1.jpg'
import Student2 from './Assets/Students/student2.jpg'
import Student3 from './Assets/Students/student3.jpg'
import Student4 from './Assets/Students/student4.jpg'
import Student5 from './Assets/Students/student5.jpg'
import Student6 from './Assets/Students/student6.jpg'
import Student7 from './Assets/Students/student7.jpg'
import Student8 from './Assets/Students/student8.jpg'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktopMini: {
    breakpoint: { max: 1450, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

AOS.init();
AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,

  offset: 120,
  delay: 0,
  duration: 800,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});


const Landing = () => {
  const [isEnglish, setIsEnglish] = useState(true);
  const [open, setOpen] = useState(false);

  return (
    <div className='landing-page' id='landinghome'>
      <header>
        <div className='header-nav-web'>
          <div className='logo'>
            <a href='/'>
              <h1>Yasalanka</h1>
              <h2>Physics</h2>
            </a>
          </div>
          <div className='btns'>
            <Tooltip
              title={`Change Language to ${isEnglish ? 'සිංහල' : 'English'}`}
            >
              <div className='translate'
                onClick={
                  () => {
                    setIsEnglish(!isEnglish);
                  }
                }
              >
                {
                  isEnglish ? 'සිං' : 'En'
                }
              </div>
            </Tooltip>
            <Button
              type='primary'
              className='signin'
              onClick={
                () => {
                  window.location.href = "/signin";
                }
              }
            >
              Sign In
            </Button>
            <Button
              type='default'
              onClick={() => { setOpen(!open) }}
              className='signup'>
              Regsiter Now
            </Button>
          </div>
        </div>
      </header>


      <div className='body'>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          className='register-model'
        >
          <div className='reg-modal'>
            <h1>
              {isEnglish ? 'Ready to Start Your Physics Journey?'
                :
                'භෞතික විද්‍යාව නිවැරදිව ඉගෙන ගන්න ඔබ සූදානම් ද ?'
              }
            </h1>
            <img src={Launching} alt='contact-img' />
            {isEnglish ? <h4>
              Click on  <span>'Chat with us'</span> and send a WhatsApp message with your <span>Name</span> and <span>'PhysicsLMS'</span> to 078 160 0027 to instantly enroll!
            </h4>
              :
              <h4>
                යසලංකා LMS හි ලියාපදිංචි වීමට සහ වැඩි විස්තර සඳහා 0781600027 අංකයට Whatsap පණිවිඩයක් යොමු කරන්න.
              </h4>
            }
            {isEnglish ? <h5>
              Prefer to speak with us?
            </h5>
              :
              <h5> </h5>
            }
            {isEnglish ? <h4>
              Call
              <span><a href='tel:+94781600027'> 076 424 8921 </a></span>
              for personal assistance.
            </h4>
              :
              <h4>
                අප හා සම්බන්ධ වීමට
                <span><a href='tel:+94764248921'> 076 424 8921 </a></span>
                අමතන්න.
              </h4>
            }

            <div className='reg-modal-btns'>
              <a
                href='https://wa.me/94781600027?text=Hi%2C%20I%20would%20like%20to%20join%20Yasalanka%20Physics%20Class.%20Can%20you%20please%20tell%20me%20more%20about%20it%3F'
                target='_blank' rel="noreferrer"
              >
                <Button
                  className='whtsapp-btn'
                  type='primary'
                >
                  <WhatsAppOutlined /> Chat with us
                </Button>
              </a>
              <a href='tel:+94764248921'
                target='_blank' rel="noreferrer"
              >
                <Button className='call-btn' type='default'>
                  <PhoneOutlined /> Call Us
                </Button>
              </a>
            </div>
          </div>
        </Modal>

        <div
          className='main-hero'>
          <h1 data-aos="fade-up">
            {isEnglish ? 'Physics Mastery Awaits at Yasalanka.'
              :
              'Yasalanka phy6 online.'
            }
          </h1>
          <h3 data-aos="fade-up">
            {isEnglish ? 'Simplifying Physics Education. Our creative platform solves physics learning challenges with ease.'
              :
              'ඔබ සොයන්නකු නම් සෑම දිනකම ඔබට අලුත් දෙයක් ඉගෙන ගත හැකිය.'
            }
          </h3>
          <div data-aos="fade-up" className='hero-btns'>
            <button
              class="learn-more"
              onClick={() => {
                document.getElementById('scroll').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <span class="circle" aria-hidden="true">
                <span class="icon arrow"></span>
              </span>
              <span
                class="button-text"
              >
                {isEnglish ? 'Learn More' : 'තවත් හදාරන්න'}
              </span>
            </button>
          </div>
          <div data-aos="zoom-in" className='hero-img'>
            <img src={HomeHeroImg} alt='hero-img' />
          </div>
        </div>

        <div className='section sesction-one' id='scroll'>
          <h2 data-aos="fade-up" >
            {isEnglish ? 'Discover the Yasalanka LMS Advantage'
              :
              'Yasalanka LMS හි පහසුව අත්විඳින්න.'
            }
          </h2>
          <h3 data-aos="fade-up">
            {isEnglish ? 'Elevate your physics skills with interactive lessons, expert guidance, and a supportive community.'
              :
              'අප ඔබ වෙත පිරිනමන සියලුම පහසුකම් සමගින් භෞතික විද්‍යාව පිළිබඳ ඔබේ කුසලතා ඉහළ නංවා ගන්න​.'
            }
          </h3>

          <div className='section-one-cards'>
            <div className='card' data-aos="fade-up">
              <div className='card-img'>
                <img src={Videos} alt='card-img' />
              </div>
              <div className='card-content'>
                <h1>{isEnglish ? 'Video recordings' : 'සුපැහැදිලි වීඩියෝ'}</h1>
                <p>
                  {isEnglish ? 'Dive into our library of enriching video recordings, enhancing your physics learning with dynamic visual explanations.'
                    :
                    'සංස්කරණය කරන ලද ශ්‍රව්‍ය දෘශ්‍ය වශයෙන් උසස් ප්‍රමිතියෙන් Eidt කරන ලද high-quality වීඩියෝ එකතුව.'
                  }
                </p>
                <h5>
                  <a href='/'>
                    Learn More
                  </a>
                </h5>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-img'>
                <img src={Cost} alt='card-img' />
              </div>
              <div className='card-content'>
                <h1>{isEnglish ? 'Affordable Learning' : 'අවම ඩේටා ගාස්තු'}</h1>
                <p>
                  {isEnglish ? 'Experience high-quality physics education at a budget-friendly cost. We believe in accessible learning for all.'
                    :
                    ' ඕනෑම ජාලයකින් ලබාදෙන ඉගෙනුම් පැකේජ හරහා අමතර ඩේටා ගාස්තු වලින් තොරව වීඩියෝ නැරඹීමට අවස්ථාව.'
                  }
                </p>
                <h5><a href='/'>Learn More</a></h5>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-img'>
                <img src={Tutes} alt='card-img' />
              </div>
              <div className='card-content'>
                <h1>{isEnglish ? 'Fresh Tutorials' : 'අංග සම්පූර්ණ නිබන්ධන'}</h1>
                <p>
                  {isEnglish ? 'We regularly update our tutorials to ensure you have access to the most up-to-date physics knowledge.'
                    : 'සරල ගැටලුවේ සිට සංකීර්ණ ගැටලුව දක්වා පෙළගැස්වූ යාවත්කාලීන නිබන්ධන කට්ටලය.'}
                </p>
                <h5><a href='/'>Learn More</a></h5>
              </div>
            </div>

          </div>
        </div>

        <div className='section section-two flex-cont' id='landingabout'>
          <div className='text left'
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <h2 id="start">
              {isEnglish ? 'Seamless Learning' : 'ඕනෑම තැනක'}
              <br />
              {isEnglish ? 'Anytime, Anywhere Access for Your Convenience.'
                :
                'ඕනෑම වෙලාවක පහසුවෙන් Log වෙන්න.'
              }
            </h2>
            <h3>
              {isEnglish ? "Yasalanka LMS offers physics education at your fingertips, ensuring ease of access from any device, whether you're on the go or at home." +
                "With our mobile-friendly platform, you can enjoy fast and convenient learning, making physics accessible whenever you choose."
                :
                'නිවසේදී හෝ පිටතදී ඕනෑම උපාංගයකින් පිවිස වේගවත්ව සහ පහසුවෙන් භෞතික විද්‍යාව ඉගෙනගැනීමට Yasalanka LMS දැන් ඔබට ඇඟිලි තුඩේ දුරින්.'
              }
            </h3>
          </div>
          <div className='image right'
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img src={LearnfromAnyware} alt='section-two-img' />
          </div>
        </div>

        <div className='section section-three flex-cont'>
          <div className='image left'
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img src={Exam} alt='section-two-img' />
          </div>
          <div className='text right'
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <h2>
              {isEnglish ? 'Confidently Pass Exams'
                :
                'විභාගය සඳහා සූදානම් වීමට අවශ්‍ය'
              }
              <br />
              {isEnglish ? 'Our Assurance for Your Success.'
                :
                'සියලු දෑ එකම තැනකින්.'
              }
            </h2>
            <h3>
              {isEnglish ? "At Yasalanka LMS, we provide you with the knowledge and tools needed to pass your physics exams with confidence." +
                "Our comprehensive courses, expert instructors, and effective learning resources ensure you're well-prepared for success, leaving exam worries behind."
                :
                "භෞතික විද්‍යාවට විශිෂ්ට සාමාර්ථයක් ලබා ගැනීම සඳහා අවශ්‍ය දැනුම සහ අනෙකුත් දෑ ඔබට Yasalanka LMS මගින් පිරිනමයි. තවද විශේෂඥ දැනුම, උපදේශකයන්ගේ මඟපෙන්වීම සහ තවත් සම්පත් සමඟ විභාගයට විශ්වාසයෙන් මුහුණදීමට අපි ඔබව සූදානම් කරන්නෙමු ."
              }
            </h3>
          </div>
        </div>

        <div className='section section-four' id='landingclesses'>
          <h2 data-aos="zoom-in">
            {isEnglish ? 'Exceptional Physics Learning Experience'
              :
              'අපගේ විශේෂතා අතර සුවිශේෂතා.'
            }
          </h2>
          <h3 data-aos="fade-up">
            {isEnglish ? "Discover the exceptional and unparalleled learning experience that sets Yasalanka LMS apart from the rest."
              :
              "දිවයිනේ පවතින අනෙකුත් භෞතික විද්‍යා පාඨමාලා අතරින් කැපී පෙනෙන ලෙසම අපි ඔබට සහය ලබා දෙන්නෙමු."
            }
          </h3>

          <div className='section-four-cards'>

            <div className='card' data-aos="fade-up">
              <div className='card-image img1'>
                <img src={HomeHeroImg} alt='card-img' />
              </div>
              <div className='card-content'>
                <h1>
                  {isEnglish ? 'Limitless Horizons of Visual Learning' :
                    'unlimited video නැරඹුම් වාර'
                  }
                </h1>
                <p>
                  {isEnglish ? 'Learn freely with endless video access and data-free learning packages.'
                    :
                    'ඉගැනුම් පැකේජ් හරහා ඩේටා නොකැපී වීඩියෝ නැරඹීමේ අවස්ථාව (විභාගය දක්වා ඉවත් නොකෙරේ).'
                  }
                </p>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-image img2'>
                <img src={HomeHeroImg} alt='card-img ' />
              </div>
              <div className='card-content'>
                <h1>
                  {isEnglish ? 'The Artistry of Special Paper Classes' :
                    'විශේෂ ප්‍රශ්න පත්‍ර පන්ති'
                  }
                </h1>
                <p>
                  {isEnglish ? 'Elevate readiness with WARMUP TESTS, ICU Papers, and LAST TRUMPS classes for exam success.'
                    :
                    'WARMUP TESTS, ICU Papers, LAST TRUMPS වැනි විභාගය ඉලක්ක කර සෑදූ ප්‍රශ්න පත්‍ර පන්තිය.'
                  }
                </p>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-image img3'>
                <img src={HomeHeroImg} alt='card-img ' />
              </div>
              <div className='card-content'>
                <h1>
                  {isEnglish ? 'A Symphony of Personalized Attention' :
                    'වෙන වෙනම අවධානය'
                  }
                </h1>
                <p>
                  {isEnglish ? 'Tailored education for all subjects, addressing challenges with individualized solutions.'
                    :
                    'RANK KING ඔස්සේ විෂයන් තුනටම මග පෙන්වමින් පුද්ගලිකව ගැටලු නිරාකරණය කර දෙනු ලැබේ.'
                  }
                </p>
              </div>
            </div>

            <div className='card' data-aos="fade-up">
              <div className='card-image img4'>
                <img src={HomeHeroImg} alt='card-img' />
              </div>
              <div className='card-content'>
                <h1>
                  {isEnglish ? 'Tutorial Delivery via courier' :
                    'Online සිසුන් සඳහා නිවසටම නිබන්ධන'
                  }
                </h1>
                <p>
                  {isEnglish ? 'Convenient kits for home practice, with cash-on-delivery service at your doorstep.'
                    :
                    'අංග සම්පූර්ණ නිබන්ධන කට්ටල ප්‍රායෝගික පරීක්ෂණ COD(Cash On Delivery) හරහා නිවසටම.'
                  }
                </p>
              </div>
            </div>


          </div>
        </div>

        <div className='section section-five'>
          <h2 data-aos="fade-up">
            {isEnglish ? 'Student Success Stories'
              :
              'අපගේ විශිෂ්ටයෝ'
            }
          </h2>
          <h3 data-aos="fade-up">
            {isEnglish ? 'Read what our satisfied students have to say about their transformative experiences with Yasalanka Physics.'
              :
              'අපගේ භෞතික විද්‍යා පන්තියෙන් ඉංජිනේරු සහ වෛද්‍ය පීඨවලට තේරී පත් වූ සිසුන්ගේ අදහස්'
            }
          </h3>

          <div className='section-five-cards' data-aos="zoom-in">
            <div className="Slider">
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                showDots={true}
                autoPlaySpeed={3000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={[
                  "tablet",
                  "mobile",
                  "desktop",
                  "superLargeDesktop",
                  "desktopMini",
                ]}
                dotListClass="custom-dot-list-style"
                activeDotClass="custom-dot-active"
              >

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student7}
                    />
                  </div>
                  <h4>Sriyani Gunarathna</h4>
                  <div className='card-content'>
                    <p>
                      අයියා subject තුනම හොදට කරගන්න හැටි කියලා දුන්නා. 3rd shy කරේ මොකක් හරි එන course එකකට යනවා කියලා එත් අයියාගේ class ගිය මුල්ම දවසේම medicine යන්න මාරම ආශාවක් අවා. Class එකට ගියපු දවසේ ඉදන් කොහොම හරි AAA අරන් එක අයියාට කියනවා කියලා හිතුවා. එ හිනය සැබැ වුනා.
                    </p>
                    <h6>District 6</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student4}
                    />
                  </div>
                  <h4>Kavindhya Weerasooriya</h4>
                  <div className='card-content'>
                    <p>
                      පොඩි කාලෙකින් ලොකු වැඩ ප්‍රමාණයක් ආවරණය වෙනව අයියගෙ class එකේ. මම exam එකට ලෑස්ති උනේ පොඩි කාලෙකින් නිසා ඒක මට ලොකු උදව්වක් වුණා. Thank you ayya.
                    </p>
                    <h6>District 7</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student8}
                    />
                  </div>
                  <h4>Arnavi Seelarathna.</h4>
                  <div className='card-content'>
                    <p>
                      මට අයියගෙ ක්ලාස් එක  ගියවර තිබූ C සාමාර්ථය A සාමාර්ථයක්  කරගන්න ලොකු උදව්වක් වුණා. thyeory  එක්කම Pastpapers , model papers කරපු නිසාත් physics වලට විතරක් නෙමේ අනිත් subject දෙකටත් අපිව උනන්දු කරපු නිසාත් subject තුනම balance එකේ කරගෙන හොඳ රිසල්ට් එකක් දාන්න පුළුවන් වුණා.
                    </p>
                    <h6>District 18</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student5}
                    />
                  </div>
                  <h4>Madurangi Thennakoon</h4>
                  <div className='card-content'>
                    <p>
                      Class පටන්ගත්ත පළවෙනි දවසෙම මම සහභාගී වුණා.මුළ ඉඳලම හැම ළමයෙක් ගැනම වෙන වෙනම හොයල බලන එක අන්තිම වෙනකන්ම තිබුණ නිසා වගේම හැම සතියකම past paper කරන නිසා ලකුණු දවසින් දවස වැඩි වුනා..Physics විතරක් නෙවෙයි අනිත් subject 2 ගැනත් අයියා බලන නිසා subject 3ම හරියට කරගන්න පුළුවන් වුනා...thank you අයියා.
                    </p>
                    <h6>District 20</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student1}
                    />
                  </div>
                  <h4>Pamodi Jayarathna</h4>
                  <div className='card-content'>
                    <p>
                      As sir say ,do work hard according to the time table .he all time guides you... believe yourself
                    </p>
                    <h6>District 23</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student3}
                    />
                  </div>
                  <h4>Dinuki Sankalpani</h4>
                  <div className='card-content'>
                    <p>
                      හැමෝටම වෙන වෙනම අවදානය දීල උගන්වන නිසා මොකුත් miss වෙන්නෙ නැහැ..පොඩ්ඩක්වත් කම්මැලි හිතෙන්නෙ නෑ..විශේශත්වය වෙන්නේ විෂයන් තුනේම පසුගිය විභාග ප්‍රශ්න පත්‍ර කරවීම නිසා ලොකු පිටුවහලක් ලැබුනා..ඒ පන්නරය නිසා තමා මට rank 136 සිට 24 දක්වා දියුණු වෙලා වෛද්‍ය පීඨයට ඇතුල් වීමට වාසනාව ලැබුණේ...Thank you soo much sir...
                    </p>
                    <h6>District 24</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student2}
                    />
                  </div>
                  <h4>Himansa Patabandige</h4>
                  <div className='card-content'>
                    <p>
                      අරමුණක්, කිසිම බලාපොරොත්තුවක් නැතුව සිටි මා හට මගේ සිහිනය කරා යන්නට අවශ්‍ය පළමු පියවර වන උසස් පෙළ විභාගය විශිෂ්ට ලෙස සමත් වීමට අවශ්‍ය මග පෙන්වීම , ගුරු හරුකම් , අවවාද , උපදෙස් ලබා දුන් විසල් අයියාට බොහොම ස්තුතියි .
                    </p>
                    <h6>District 38</h6>
                  </div>
                </div>

                <div className="card">
                  <div className='img'>
                    <img className="cardImg" alt="a" src={Student6}
                    />
                  </div>
                  <h4>Kalani Himansa</h4>
                  <div className='card-content'>
                    <p>
                      Mt 1st shy eke thibbe s3i. Padam krn vidiytk danagen hitiyeth na.aiya thamai subject 3m gana balala papers krwl exam goda dala dunne
                    </p>
                    <h6>District 62</h6>
                  </div>
                </div>

              </Carousel>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${Contact})`,
          }}
          className='section section-six'
          data-aos="fade-up"
          id='landingcontact'
        >
          <h2>{isEnglish ? 'Connect with Us for Questions and Support.'
            :
            'තාක්ෂණික සහය සඳහා WhatsApp ඔස්සේ සම්බන්ධ වන්න'
          }</h2>
          <h3>
            <a
              href='https://wa.me/94781600027?text=Hi%2C%20I%20would%20like%20to%20join%20Yasalanka%20Physics%20Class.%20Can%20you%20please%20tell%20me%20more%20about%20it%3F'
              target='_blank' rel="noreferrer"
            >
              Contact Us
            </a>
          </h3>
        </div>

      </div>

      <footer>
        <div className='footer-sec'>
          <div className='logo'>
            <a href='/'>
              <h1>Yasalanka</h1>
              <h2>Physics</h2>
            </a>
          </div>
          <div className='text'>
            <p>
              Simplifying Physics Education. Our creative platform solves physics learning challenges with ease.
            </p>
          </div>
        </div>

        <div className='footer-sec'>
          <section className='social-media'>
            <h3>
              Quick Links
            </h3>
            <div className='links'>
              <a onClick={
                () => {
                  document.getElementById('landinghome').scrollIntoView({ behavior: 'smooth' });
                }}>
                Home
              </a>
              <a
                onClick={
                  () => {
                    document.getElementById('landingabout').scrollIntoView({ behavior: 'smooth' });
                  }}
              >
                About
              </a>
              <a onClick={
                () => {
                  document.getElementById('landingclesses').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Classes
              </a>
              <a
                onClick={
                  () => {
                    document.getElementById('landingcontact').scrollIntoView({ behavior: 'smooth' });
                  }}
              >
                Contact
              </a>
            </div>
          </section>
        </div>

        <div className='footer-sec'>
          <h3>
            Contacts
          </h3>
          <div className='text'>
            <p>
              <span><EnvironmentTwoTone /></span>
              Yasalanka Education Center, Hanguranketha, Sri Lanka.
            </p>
            <p>
              <span><PhoneTwoTone /></span>(076) 424 8921
            </p>
            <p>
              <span><MailTwoTone /></span>
              <a href='mailto:yasalankaphysics@gmail.com' >
                yasalankaphysics@gmail.com
              </a>
            </p>
          </div>
        </div>

      </footer>
      <section className='copy-right'>
        <p>
          Yasalanka Physics ©2023 Created by <a href="http://lumosystechnologies.com/" target="_blank" rel="noreferrer">LumoSys Technologies</a>
        </p>
      </section>
    </div>
  )
}

export default Landing