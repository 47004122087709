/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import axios from 'axios';
const KEY = 'AIzaSyDdGsgc3EWQljOE2wOZvUvaX4XM2oEc8m8';

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3/',
    params: {
        part: 'snippet,contentDetails,status',
        key: KEY
    }
})