/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { createSlice } from '@reduxjs/toolkit';

export const usersDataSlice = createSlice({
    name: 'usersData',
    initialState: {
        usersData: {},
    },
    reducers: {
        addUsersData: (state, action) => {
            state.usersData = action.payload;
        }
    },
});

export const { addUsersData } = usersDataSlice.actions;

export default usersDataSlice.reducer;
