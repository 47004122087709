/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { createSlice } from '@reduxjs/toolkit';

export const videosSlice = createSlice({
    name: 'videos',
    initialState: {
        allVideos: [], // Initialize allVideos as an empty array
    },
    reducers: {
        addVideoDataToCategory: (state, action) => {
            const { category, videoData } = action.payload;
            state.allVideos = [...state.allVideos, { category, videoData }];
        },
    },
});

export const { addVideoDataToCategory } = videosSlice.actions;

export default videosSlice.reducer;
