/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useState, useRef, useEffect } from 'react'

import {
  Table,
  Input,
  Button,
  Space,
  Avatar,
  Tag,
  Popconfirm,
  Radio,
  Badge,
  Spin,
  Col,
  Divider,
  Drawer,
  Row,
  Form,
  Checkbox,
  Image,
  message,
  Tooltip
} from 'antd';
import Highlighter from 'react-highlight-words';

import { SearchOutlined, RedoOutlined } from '@ant-design/icons';

// Firebase
import { db } from '../../../../../firebase'
import {
  doc,
  updateDoc,
  getDoc
} from "firebase/firestore";

// Redux
import { useSelector } from 'react-redux'

import './style.sass'
import moment from 'moment';

const UsersTable = ({ data }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [tableData, setTableData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [classFilters, setClassFilters] = useState([]);

  // Drawer
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editClasses, setEditClasses] = useState(false);
  const [editIndex, setEditIndex] = useState(false);
  const formRef = useRef(null);
  const indexFormRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  const classes = useSelector((state) => state.classData.classData);

  useEffect(() => {
    setTableData(data)
  }, [data])

  useEffect(() => {
    if (classes) {
      const filters = classes.map((cls) => {
        return {
          text: cls.name,
          value: cls.id
        }
      })
      setClassFilters(filters);
    }
  }, [classes])


  const getClassName = (classId) => {
    const cls = classes.find((cls) => cls.id === classId)
    return cls?.name
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  // End of search

  const changeStatus = async (changedStatus, data) => {
    if (!data) {
      return;
    }
    if (changedStatus === data.activeStatus) {
      return;
    }
    setLoading(true);

    // update student count
    if (changedStatus === "active" && data.isNewUser) {
      const classes = data.classes;
      for (const cls of classes) {
        try {
          updateStudentCount(cls, 'plus');
        } catch (error) {
          console.error("Error updating student count:", error);
        }
      }
      setIsNewUserFalse(data.uid);
    }

    // handle deleted status
    if (changedStatus === "deleted" && data.activeStatus !== "deleted") {
      const classes = data.classes;
      for (const cls of classes) {
        try {
          updateStudentCount(cls, 'minus');
        } catch (error) {
          console.error("Error updating student count:", error);
        }
      }
      updateUserClasses([], data?.uid);
    }

    // update user status
    updateUserStatus(data?.uid, changedStatus);
  }

  const setIsNewUserFalse = async (userId) => {
    const docRef = doc(db, "users", userId);
    try {
      await updateDoc(docRef, {
        isNewUser: false
      });
    } catch (error) {
      console.error("Error updating isNewUser:", error);
      setLoading(false);
    }
  }

  const updateStudentCount = async (classId, operator) => {
    const docRef = doc(db, "classes", classId);
    try {
      const prevCount = await getCurrentUsersCount(classId);
      if (!isNaN(prevCount)) {
        if (operator === 'plus') {
          await updateDoc(docRef, {
            studentsCount: prevCount + 1
          });
        } else if (operator === 'minus') {
          await updateDoc(docRef, {
            studentsCount: prevCount - 1
          });
        }
      } else {
        console.error("Invalid previous student count:", prevCount);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating student count:", error);
      setLoading(false);
    }
  }

  const getCurrentUsersCount = async (classId) => {
    const docRef = doc(db, "classes", classId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.studentsCount || 0;
    } else {
      console.error("No such document!");
      setLoading(false);
      return NaN;
    }
  };

  const updateUserStatus = async (userId, status) => {
    const docRef = doc(db, "users", userId);
    try {
      await updateDoc(docRef, {
        activeStatus: status
      });

      // Re-render table
      setTableData((prevData) =>
        prevData.map((user) =>
          user.uid === userId ? { ...user, activeStatus: status } : user
        )
      );
      setLoading(false);

    } catch (error) {
      console.error("Error updating user status:", error);
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Action",
      key: "action",
      width: "5%",
      sorter: (a, b) => a.activeStatus.localeCompare(b.activeStatus),
      defaultSortOrder: 'descend',
      render: (record) => {
        return (
          <Space>
            {record.isNewUser ?
              <Badge
                color='purple'
                count="new"
                size='small'
              >
                <Avatar
                  onClick={openUserDrawer(record)}
                  style={{
                    cursor: "pointer",
                  }}
                  src={record.photoURL === "" ? "https://www.w3schools.com/howto/img_avatar.png" : record.photoURL}
                />
              </Badge>
              :
              <Avatar
                onClick={openUserDrawer(record)}
                style={{
                  cursor: "pointer",
                }}
                src={record.photoURL === "" ? "https://www.w3schools.com/howto/img_avatar.png" : record.photoURL}
              />
            }
          </Space>
        )
      }
    },
    {
      title: 'Index No',
      dataIndex: 'indexNumber',
      key: 'indexNumber',
      ...getColumnSearchProps('indexNumber'),
      sorter: (a, b) => a.indexNumber.localeCompare(b.indexNumber),
      width: '10%',
    },
    {
      title: 'User Name',
      dataIndex: 'fullname',
      key: 'fullname',
      ...getColumnSearchProps('fullname'),
      width: '15%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Status',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      width: '10%',
      filters: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Hold',
          value: 'hold',
        },
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ],
      onFilter: (value, record) => record.activeStatus.indexOf(value) === 0,
      defaultFilteredValue: ['active', 'pending', 'hold'],
      render: (status, record) => {
        let chnagedStatus;
        return (
          <Popconfirm
            title="Change status?"
            description={
              <Radio.Group
                defaultValue={status}
                buttonStyle="solid"
                onChange={(e) => chnagedStatus = e.target.value}
              >
                <Radio.Button value="active">Active</Radio.Button>
                <Radio.Button value="hold">Hold</Radio.Button>
                <Radio.Button value="deleted">Deleted</Radio.Button>
              </Radio.Group>
            }
            onConfirm={() => changeStatus(chnagedStatus, record)}
          >
            <Tag
              color={
                status === "active" ? "green" :
                  status === "pending" ? "purple" :
                    status === "hold" ? "orange" :
                      status === "inactive" ? "gray" :
                        status === "deleted" ? "red" : "blue"
              }
              style={{
                cursor: "pointer",
                color:
                  status === "active" ? "green" :
                    status === "pending" ? "purple" :
                      status === "hold" ? "orange" :
                        status === "inactive" ? "gray" :
                          status === "deleted" ? "red" : "blue"
              }}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>
          </Popconfirm>
        )
      }
    },
    {
      title: 'Classes',
      dataIndex: 'classes',
      key: 'classes',
      width: '20%',
      filters: classFilters,
      onFilter: (value, record) => {
        return record.classes.includes(value)
      },
      render: (classes) => {
        return (
          <div style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            gap: "0.5em",
          }}>
            {classes.map((cls) => {
              return (
                <Tag
                  key={cls}
                  color="blue"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {
                    getClassName(cls)
                  }
                </Tag>
              )
            })}
          </div>
        )
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      filters: [
        {
          text: 'Student',
          value: 'student',
        },
        {
          text: 'Super Admin',
          value: 'super_admin',
        },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
      render: (role) => {
        return (
          <Tag
            color={
              role === "student" ? "green" :
                role === "admin" ? "purple" :
                  role === "super_admin" ? "red" : "blue"
            }
            style={{
              cursor: "pointer",
              color:
                role === "student" ? "green" :
                  role === "admin" ? "purple" :
                    role === "super_admin" ? "red" : "blue"
            }}
          >
            {role.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key) { return key.toUpperCase() })}
          </Tag>
        )
      }
    },
  ];

  // Drawer functions
  // -----------------------------------------------------------------------------
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setEditClasses(false);
    setEditIndex(false);
  };

  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );

  const openUserDrawer = (record) => () => {
    showDrawer();
    setSelectedUser(record);
  }

  // Set classes in form
  const setClassesInForm = () => {
    if (selectedUser && formRef.current) {
      const classes = selectedUser.classes;
      const formValues = {
        classes: classes
      }
      formRef.current.setFieldsValue(formValues);
    }
  }

  const setIndexNumberInForm = () => {
    if (selectedUser && indexFormRef.current) {
      const indexNumber = selectedUser.indexNumber;
      const formValues = {
        indexNumber: indexNumber
      }
      indexFormRef.current.setFieldsValue(formValues);
    }
  }

  useEffect(() => {
    setClassesInForm()
    setIndexNumberInForm()
  }, [selectedUser, editClasses])

  // Update index number
  const onIndexUpdateFinish = (values) => {
    const indexNumber = values.indexNumber;
    if (indexNumber === selectedUser.indexNumber) {
      warning();
      return;
    }
    setLoading(true);
    updateIndexNumber(indexNumber, selectedUser.uid);
  }

  // Update user classes
  const onUpdateFinish = (values) => {
    if (selectedUser.activeStatus === "pending") {
      error();
      return;
    }
    setLoading(true);
    const selectedUserClasses = selectedUser.classes;
    const valuesClasses = values.classes;

    // compare array values with selectedUser.classes array
    const isSame = selectedUserClasses.length === valuesClasses.length && selectedUserClasses.every((value, index) => value === valuesClasses[index]);

    if (!isSame) {
      // chehck added classes
      const addedClasses = valuesClasses.filter((cls) => !selectedUserClasses.includes(cls));
      // check removed classes
      const removedClasses = selectedUserClasses.filter((cls) => !valuesClasses.includes(cls));

      // update added classes
      for (const cls of addedClasses) {
        try {
          updateStudentCount(cls, 'plus');
        } catch (error) {
          console.error("Error updating student count:", error);
        }
      }

      // update removed classes
      for (const cls of removedClasses) {
        try {
          updateStudentCount(cls, 'minus');
        } catch (error) {
          console.error("Error updating student count:", error);
        }
      }
      updateUserClasses(valuesClasses, selectedUser.uid);
      setEditClasses(false);
      setOpen(false);
    } else {
      setLoading(false);
      warning();
    }
  }

  const updateIndexNumber = async (indexNumber, uid) => {
    const docRef = doc(db, "users", uid);
    try {
      await updateDoc(docRef, {
        indexNumber: indexNumber
      });
      setLoading(false);
      success();
      setEditIndex(false);
    } catch (error) {
      console.error("Error updating index number:", error);
      setLoading(false);
      somethingWentWrong();
    }

    // Re-render table
    setTableData((prevData) =>
      prevData.map((user) =>
        user?.uid === selectedUser?.uid ? { ...user, indexNumber: indexNumber } : user
      )
    );
  }


  const updateUserClasses = async (classes, uid) => {
    const docRef = doc(db, "users", uid);
    try {
      await updateDoc(docRef, {
        classes: classes
      });
      setLoading(false);
      success();
    } catch (error) {
      console.error("Error updating user classes:", error);
      setLoading(false);
      somethingWentWrong();
    }

    // Re-render table
    setTableData((prevData) =>
      prevData.map((user) =>
        user?.uid === selectedUser?.uid ? { ...user, classes: classes } : user
      )
    );
  }

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'No changes made!',
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Pleases activate the user first!',
    });
  };

  const somethingWentWrong = () => {
    messageApi.open({
      type: 'error',
      content: 'Something went wrong!',
    });
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'User updated successfully!',
    });
  };

  return <>
    {contextHolder}
    <Spin
      spinning={!tableData || loading}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          defaultPageSize: 8,
          showSizeChanger: true,
          pageSizeOptions: ["8", "10", "20", "30", "50"],
          position: ["bottomRight"]
        }}
        scroll={{ x: 1000 }}
      />
    </Spin>

    {selectedUser && <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          className="site-description-item-profile-p"
          style={{
            marginBottom: 24,
          }}
        >
          User Profile
        </p>
        <Tooltip
          title="Reload form"
        >
          <Button
            type="default"
            onClick={() => {
              setClassesInForm();
              setIndexNumberInForm();
            }}
          >
            <RedoOutlined />
          </Button>
        </Tooltip>
      </div>

      <Row style={{
        display: "flex",
        justifyContent: "center",
      }}>
        <Image
          width={100}
          style={{
            boxShadow: "0 0 5px #ccc",
          }}
          src={selectedUser?.photoURL === "" ? "https://www.w3schools.com/howto/img_avatar.png" : selectedUser?.photoURL}
        />
      </Row>

      <p className="site-description-item-profile-p">
        Index Number
      </p>
      <Row
        style={{
          marginTop: "1em",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setEditIndex(!editIndex);
              setIndexNumberInForm();
            }}
          >
            {editIndex ? "Discard" : "Edit"}
          </Button>
        </Col>
        <Col>
          <Form
            layout="vertical"
            hideRequiredMark
            disabled={!editIndex}
            onFinish={onIndexUpdateFinish}
            ref={indexFormRef}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              gap: "1em",
            }}
          >
            <Form.Item
              name="indexNumber"
            >
              <Input
                placeholder="Index Number"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <p className="site-description-item-profile-p">Personal</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Full Name" content={selectedUser?.fullname} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Email" content={selectedUser?.email} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="School" content={selectedUser?.school} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Contact" content={selectedUser?.contact} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="District" content={selectedUser?.district} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Role" content={selectedUser?.role} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DescriptionItem
            title="Address"
            content={selectedUser?.address}
          />
        </Col>
      </Row>
      <Divider />
      <p className="site-description-item-profile-p">Other</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Status" content={selectedUser?.activeStatus} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Physics Results" content={selectedUser?.physicsResult} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="Stream" content={selectedUser?.stream} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Attempt" content={selectedUser?.attempt + " Shy"} />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title="How did know" content={selectedUser?.howDidYouKnow} />
        </Col>
        <Col span={12}>
          <DescriptionItem title="Created at" content={
            moment(selectedUser?.createdAt).format("DD MMM YYYY")
          } />
        </Col>
      </Row>

      {/* 
      Class details
       */}
      <Divider />
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p className="site-description-item-profile-p">Classes</p>
        <div style={{
          display: "flex",
          gap: "1em",
        }}>
          <Button
            type="primary"
            onClick={() => setEditClasses(!editClasses)}
          >
            {editClasses ? "Discard" : "Edit"}
          </Button>
        </div>
      </Row>

      <Form
        layout="vertical"
        hideRequiredMark
        disabled={!editClasses}
        onFinish={onUpdateFinish}
        ref={formRef}
      >
        <Row>
          <Form.Item
            name="classes"
          >
            <Checkbox.Group>
              {
                classes ? classes.map((cls) => {
                  return (
                    <Col
                      key={cls.id}
                      span={10}
                    >
                      <Checkbox
                        value={cls.id}
                      >
                        {cls.name}
                      </Checkbox>
                    </Col>
                  )
                }) : null
              }
            </Checkbox.Group>
          </Form.Item>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Row>
      </Form>

    </Drawer>}
  </>;
}

export default UsersTable