/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import Routes from "./Routes";

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
