/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */
import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';

// Firebase
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, doc, db } from '../../../firebase'
import { onSnapshot } from "firebase/firestore";

import MathsBg from '../../../Assets/Images/MathsBg.jpg'
import LearnImg from '../../../Assets/Images/learnImg.png'

import './style.sass'
import OopsComp from "./OopsComp";

// Images
import Lottie from 'react-lottie';
import EmailJson from '../../../Assets/Images/AF11QQQD9v.json'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const Signin = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false)
  const [isEmalVerified, setIsEmalVerified] = useState(true)
  const [email, setEmail] = useState('')
  const [isTimeOut, setIsTimeOut] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('yasalankalmsuser'))
    if (user) {
      window.location.href = '/dashboard'
    }
  }, [])

  const onFinishFailed = (errorInfo) => {
    onFinishFailedError('error')
  };

  const onFinish = async (values) => {
    const { email, password } = values

    try {
      setIsLoading(true)
      const res = await signInWithEmailAndPassword(auth, email, password)

      if (!res.user.emailVerified) {
        setIsLoading(false)
        setIsEmalVerified(false)
        setEmail(res.user.email)
        await sendEmailVerification(auth.currentUser)
      } else {
        setIsEmalVerified(true)

        let userData = null;
        if (res?.user) {
          const docRef = doc(db, "users", res?.user.uid);
          onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
              userData = doc.data();
              openNotificationWithIcon('success')
              localStorage.setItem("yasalankalmsuser", JSON.stringify(auth.currentUser));
            } else {
              setIsLoading(false)
              openNotificationWithIconAccountDeleted('error')
              return
            }
          }
          );
        }

        setTimeout(() => {
          if (userData?.activeStatus === 'active') {
            window.location.href = '/dashboard'
          } else if (userData?.activeStatus === 'pending') {
            localStorage.removeItem('yasalankalmsuser');
            window.location.href = '/pending'
          } else if (userData?.activeStatus === 'declined') {
            localStorage.removeItem('yasalankalmsuser');
            window.location.href = '/deleted'
          } else if (userData?.activeStatus === 'hold') {
            localStorage.removeItem('yasalankalmsuser');
            window.location.href = '/hold'
          } else if (userData?.activeStatus === 'deleted') {
            localStorage.removeItem('yasalankalmsuser');
            window.location.href = '/deleted'
          }
          setIsLoading(false)
        }, 2000)
      }

    } catch (error) {
      setIsLoading(false)
      console.log(error.message)
      if (error.message === 'Firebase: Error (auth/user-not-found).') {
        openNotificationWithIconAcNotFound('error')
      } else if (error.message === 'Firebase: Error (auth/wrong-password).') {
        openNotificationWithIconWrongPw('error')
      } else if (error.message === 'Firebase: Error (auth/too-many-requests).') {
        openNotificationWithEmailSent('error')
      }
      else {
        openNotificationWithIcon('error')
      }
    }
  };

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: {
        success:
          'Successfully logged in'
        , error: 'Error'
      }[type],
      description: {
        success: "You're successfully logged in, Enjoy!"
        , error: 'Something went wrong'
      }[type],
    });
  };

  const onFinishFailedError = (type) => {
    api[type]({
      message: { success: 'Successfully Registered', error: 'Error' }[type],
      description: {
        success: 'Please wait for the admin to approve your account',
        error: 'Please enter an email address and password'
      }[type],
    });
  }

  const openNotificationWithIconWrongPw = (type) => {
    api[type]({
      message: { success: 'Successfully Registered', error: 'Wrong password' }[type],
      description: {
        success: 'Please wait for the admin to approve your account', error:
          'Please enter the correct password'
      }[type],
    });
  };

  const openNotificationWithIconAcNotFound = (type) => {
    api[type]({
      message: {
        success: 'Successfully Registered', error:
          'Account not found'
      }[type],
      description: {
        success: 'Please wait for the admin to approve your account', error:
          'Please use the correct email address'
      }[type],
    });
  };

  const openNotificationWithEmailSent = (type) => {
    api[type]({
      message: {
        success: 'Successfully Registered', error:
          'We have sent a verification email to your email address'
      }[type],
      description: {
        success: 'We have sent a verification email to your email address', error:
          'Please check your inbox or use the correct email address'
      }[type],
    });
  };

  const openNotificationWithIconAccountDeleted = (type) => {
    api[type]({
      message: {
        success: 'Successfully Registered', error:
          'Account not found'
      }[type],
      description: {
        success: 'Please wait for the admin to approve your account', error:
          'Your account has been deleted. Please contact us for more information'
      }[type],
    });
  }
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: EmailJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
    if (!isEmalVerified) {
      const timeoutId = setTimeout(() => {
        setIsTimeOut(true);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isEmalVerified]);

  const renderEmailVerify = () => {
    return (
      <>
        {!isTimeOut ? (
          <div className="oops">
            <OopsComp />
            <h2>Oops! Your email is not verified yet.</h2>
            <br />
            <Spin indicator={antIcon} />
            <br />
            <p>
              Sending verification mail to your email address
            </p>
          </div>
        ) : (
          <div class="verification-panel"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                width: '440px',
                height: '230px',
              }}
            >
              <Lottie
                options={lottieOptions}
                height={300}
                width={300}
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </div>
            <p>
              We have sent a verification email to <b>{email}</b>
            </p>
            <br />
            <Button
              type="primary"
              onClick={() => {
                window.open('https://mail.google.com/mail/u/0/#inbox');
              }
              }
            >
              Go to inbox and verify
            </Button>
            <br />
            <h3>
              <a href="/signin">Sign in</a> if you have already verified your email.
            </h3>
          </div>
        )}
      </>
    )
  }

  return (
    <div className='signin-page'>
      {contextHolder}

      <Row>
        <Col className='signup-left'>
          <img src={MathsBg} alt='signup' className='bg' />
          <div className='logo'>
            <div className='logo'>
              <a href='/'>
                <h1>Yasalanka</h1>
                <h2
                  style={{ fontSize: '11px' }}
                >Physics</h2>
              </a>
            </div>
          </div>
          <div className='texts'>
            <h3>Welcome to,</h3>
            <h1>Yasalanka Physics</h1>
            <h4>Education Hub</h4>

            <h5>
              Let's have a look what's new
            </h5>
          </div>
          <div className='image'>
            <img src={LearnImg} alt='signup' />
          </div>
        </Col>
        {isEmalVerified ?
          <Col className='signup-right'>
            <Spin spinning={isLoading}>
              <h1>Sign in to LMS</h1>
              <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Email address!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The input is not a valid Gmail address (e.g., user@gmail.com)!'));
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="visaljayarathna@gmail.com" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row span={24}>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password',
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Input password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  className="submit-button"
                  span={24}>
                  <Col span={24}>
                    <Form.Item>
                      <Button className='loginbutton' style={{
                        width: '200px',
                        padding: '1.5em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }} type="primary" htmlType="submit">
                        Login
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <div className="sign-in">
                  <h1>
                    Don't have an account? <a
                      // default message is "Hi, I would like to join Yasalanka Physics Class. Can you please tell me more about it?"
                      href='https://wa.me/94781600027?text=Hi%2C%20I%20would%20like%20to%20join%20Yasalanka%20Physics%20Class.%20Can%20you%20please%20tell%20me%20more%20about%20it%3F'
                      target='_blank' rel="noreferrer"
                    >
                      Contact Us
                    </a>
                  </h1>
                </div>
              </Form>
            </Spin>
          </Col>
          :
          <Col className='email-verify'>
            {renderEmailVerify()}
          </Col>
        }
      </Row>

    </div >
  )
}

export default Signin