/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import { createSlice } from '@reduxjs/toolkit';

export const homeDataSlice = createSlice({
    name: 'homeData',
    initialState: {
        homeData: null,
    },
    reducers: {
        setHomeData: (state, action) => {
            state.homeData = action.payload;
        }
    },
});

export const { setHomeData } = homeDataSlice.actions;

export default homeDataSlice.reducer;