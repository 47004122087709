/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useState, useEffect } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Spin } from 'antd';
// import { DatePicker } from 'antd';
import { Button, Form, Col, Empty, Menu, Tag, AutoComplete, Input } from 'antd';
import dayjs from 'dayjs';

// Redux
import { useSelector, useDispatch } from 'react-redux'
import { addVideoDataToCategory } from '../../../Common/Slices/videosSlice';

// YT-Data API
import youtube from '../../../../youtube';

import './style.sass';
// import moment from 'moment/moment';

// Image
import nodataImage from '../../../../Assets/Images/nodata.jpg'

// const { RangePicker } = DatePicker;

const Youtube = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);

  const [searchOptions, setSearchOptions] = useState([]);
  const [usersClasses, setUsersClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState();

  const [clickToggle, setClickToggle] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const classData = useSelector((state) => state.classData.classData);
  const videoData = useSelector((state) => state.videos.allVideos);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('yasalankalmsuser'));
    if (!user) {
      window.location.href = '/signin';
    }
    // set users classes
    if (userData?.role !== 'student') {
      setUsersClasses(classData);
      setSelectedClass(classData[0]);
    } else {
      const classesOfStudent = userData?.classes;
      const classes = classData?.filter((classData) => classesOfStudent?.includes(classData?.id));
      setUsersClasses(classes);
      setSelectedClass(classes[0]);
    }
  }, []);

  useEffect(() => {
    setVideos([]);
    setFilteredVideos([]);
    getUnlistedPlaylistVideos();
  }, [selectedClass, clickToggle]);


  const getUnlistedPlaylistVideos = async () => {
    if (!selectedClass) {
      return;
    } else {
      setIsLoading(true);
    }
    if (selectedClass?.yturl === "") {
      setIsLoading(false);
      // TODO: Show notification
    }
    const playListId = selectedClass?.yturl?.split('list=')[1];

    let isNew = true;
    videoData?.forEach((data) => {
      if (data.category === selectedClass?.id) {
        isNew = false;
      }
    });

    if (!isNew) {
      const vd = videoData?.find((data) => data.category === selectedClass?.id)?.videoData;

      setVideos(vd);
      setFilteredVideos(vd);
      setIsLoading(false);
      return;

    } else {
      try {
        if (selectedClass?.yturl !== "") {
          const response = await youtube.get('/playlistItems', {
            params: {
              playlistId: playListId,
              maxResults: 100,
            },
          });
          setVideos(response.data.items);
          setFilteredVideos(response.data.items);

          dispatch(addVideoDataToCategory(
            {
              category: selectedClass?.id,
              videoData: response.data.items
            }));
        } else {
          dispatch(addVideoDataToCategory(
            {
              category: selectedClass?.id,
              videoData: []
            }));
        }
        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching playlist videos', error);
        setIsLoading(false);
      }
    }

  };

  const handleOpenVideo = (videoData) => {
    sessionStorage.setItem('yasalanka-videoData', JSON.stringify(videoData));
    window.open('/dashboard/youtube/video');
  }

  const renderVideoList = (data) => {
    // sort videos by date
    const videos = [...data].sort((a, b) => {
      const dateA = dayjs(a.snippet.publishedAt).unix();
      const dateB = dayjs(b.snippet.publishedAt).unix();
      return dateB - dateA;
    });

    return (
      <>
        {videos && videos?.map(function (allData) {
          const data = allData?.snippet;
          return (
            <div key={data?.videoId} className="ytblock">
              <div className="section1">
                {data?.thumbnails?.default?.url ?
                  <img className="ytImage" src={data.thumbnails.default?.url} alt="ytImage" />
                  :
                  <img className="ytImage" src={nodataImage} alt="ytImage" />
                }
              </div>
              <div className="section2">
                <div className="title">
                  <h2 className="topic">{data?.title}</h2>
                  {/* <h2 className="date">{
                    moment(data.publishedAt).format('DD MMM YYYY')
                  }</h2> */}
                </div>
                <p className="description">{
                  data?.description.length > 250 ? data?.description.substring(0, 250) + '...' : data?.description
                }</p>
                <Button
                  type='primary'
                  onClick={
                    () => {
                      handleOpenVideo(allData)
                    }
                  }>
                  Watch Video
                </Button>
              </div>
            </div>
          )
        })
        }
      </>
    );
  };

  // on playlist select
  const onClick = ({ id }) => {
    setVideos([]);
    setFilteredVideos([]);
  };

  // const onRangeChange = (dates, dateStrings) => {
  //   if (dates) {
  //     setFilteredVideos([]);
  //     // convert date string to timestamp
  //     const fromDate = dayjs(dateStrings[0]).unix();
  //     const toDate = dayjs(dateStrings[1]).unix();

  //     // filter videos
  //     const filteredVideos = videos?.filter((video) => {
  //       const videoDate = dayjs(video.snippet.publishedAt).unix();
  //       return videoDate >= fromDate && videoDate <= toDate;
  //     });
  //     setFilteredVideos(filteredVideos);
  //   } else {
  //     setFilteredVideos([]);
  //     setFilteredVideos(videos);
  //   }
  // };

  // const rangePresets = [
  //   { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()], },
  //   { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()], },
  //   { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()], },
  //   { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()], },
  // ];

  const searchResult = (query) => {
    const searchFilteredVideos = videos?.filter((video) => {
      return video.snippet.title.toLowerCase().includes(query.toLowerCase());
    });
    return searchFilteredVideos.map((video) => ({
      value: video.snippet.title,
      label: video.snippet.title,
    }));
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchOptions(value ? searchResult(value) : []);
    } else {
      setSearchOptions([]);
      setFilteredVideos(videos);
    }
  };

  const onSearchResultSelect = (value) => {
    setFilteredVideos(
      filteredVideos?.filter((video) => video.snippet.title === value)
    )
  };

  return (
    <div className="youtube">
      <Col
        className='video-filters'
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          className='video-filters__left'
          style={{
            display: 'flex',
            gap: '16px',
          }}
        >
          <Form.Item>
            <Dropdown
              overlay={
                <Menu onClick={onClick}>
                  {usersClasses?.map((cls) => (
                    <Menu.Item
                      key={cls.id}
                      onClick={() => {
                        setSelectedClass(cls);
                        setClickToggle(!clickToggle);
                      }}
                    >
                      {cls.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button onClick={(e) => e.preventDefault()}>
                <Space>
                  {selectedClass ? selectedClass.name : 'Select Your class'}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Form.Item>

          <Form.Item>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              options={searchOptions}
              onSelect={onSearchResultSelect}
              onSearch={handleSearch}
              style={{
                width: 300,
              }}
            >
              <Input.Search placeholder="Search Video" enterButton />
            </AutoComplete>
          </Form.Item>

          {/* <Form
            className='video-filters__date'
          >
            <Form.Item>
              <Space direction="vertical">
                <RangePicker
                  presets={rangePresets}
                  onChange={onRangeChange} />
              </Space>
            </Form.Item>
          </Form> */}
        </div>

        <Form.Item
          label="All Videos"
        >
          <Tag style={{
            padding: '4px 20px',
          }} color="blue">{filteredVideos?.length}</Tag>
        </Form.Item>
      </Col>

      <div className="scrollableDiv" >
        <Spin
          style={{
            marginTop: '100px',
          }}
          spinning={isLoading}
          tip="Loading..."
        >
          {filteredVideos?.length > 0 ?
            renderVideoList(filteredVideos)
            :
            <>
              {!isLoading &&
                <>
                  {videos?.length > 0 ?
                    <Empty
                      image={nodataImage}
                      imageStyle={{
                        height: 200,
                      }}
                      style={{
                        marginTop: '100px',
                      }}
                      description={
                        <p
                          style={{
                            color: '#A4A4A4',
                            fontSize: '20px',
                            fontWeight: '300',
                            marginTop: '30px',
                          }}
                        >
                          No videos found for the selected fileters.
                        </p>
                      }
                    />
                    :
                    <Empty
                      image={nodataImage}
                      imageStyle={{
                        height: 200,
                      }}
                      style={{
                        marginTop: '100px',
                      }}
                      description={
                        <p
                          style={{
                            color: '#A4A4A4',
                            fontSize: '20px',
                            fontWeight: '300',
                            marginTop: '30px',
                          }}
                        >
                          This class has no videos yet.
                        </p>
                      }
                    />
                  }
                </>
              }
            </>

          }

        </Spin>
      </div>
    </div >

  )
}

export default Youtube