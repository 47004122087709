/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React from 'react'

import './style.css'

const OopsComp = () => {
    return (
        <div className="wrapper">
            <div className="cloud">
                <div className="cloud_left"></div>
                <div className="cloud_right"></div>
            </div>
            <div className="rain">
                <div className="drop"></div>
                <div className="drop"></div>
                <div className="drop"></div>
                <div className="drop"></div>
                <div className="drop"></div>
            </div>
            <div className="surface">
                <div className="hit"></div>
                <div className="hit"></div>
                <div className="hit"></div>
                <div className="hit"></div>
                <div className="hit"></div>
            </div>
        </div>
    )
}

export default OopsComp