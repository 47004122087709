/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React from 'react'

import Image from './waiting.png'
import Logo from '../../../../Assets/Logo/logoMini.png'

import './style.sass'
import { Button } from 'antd'

const PendingPage = () => {
    return (
        <div className='pending-page'>
            <p>
                Your account is pending approval. Please wait for the admin to approve your account.
            </p>
            <img
                src={Image}
                alt="pending"
            />
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    margin: '20px 0'
                }}
            >
                <Button
                    type="primary"
                    onClick={() => window.location.href = '/signin'}
                >
                    Back to Signin
                </Button>

                <Button
                    type="default"
                    onClick={
                        () => window.location.href = 'tel:+94712345678'
                    }
                >
                    Contact Admin
                </Button>
            </div>
            <div className="logo">
                <img src={Logo} alt="logo" />
                <div>
                    <h1>Yasalanka</h1>
                    <h2>Physics</h2>
                </div>
            </div>
        </div>
    )
}

export default PendingPage