/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useEffect, useRef, useState } from 'react'

import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Table,
    notification,
    Spin,
    Tooltip,
    Popconfirm
} from 'antd';

import {
    PlusOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    RedoOutlined
} from '@ant-design/icons';

import { db } from '../../../../firebase';
import {
    collection,
    doc,
    getDocs,
    setDoc,
    deleteDoc
} from "firebase/firestore";

import { v4 as uuidv4 } from 'uuid';

const Classes = () => {
    const [openAddClass, setOpenAddClass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [classData, setClassData] = useState(null);

    const [openEditClass, setOpenEditClass] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [editSelectedClassData, setEditSelectedClassData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const formRef = useRef(null);

    const columns = [
        {
            title: 'Class Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total Students',
            dataIndex: 'studentsCount',
            key: 'studentsCount',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Youtube',
            key: 'yturl',
            dataIndex: 'yturl',
            render: yturl => (
                // if yturl is not empty add right icon
                yturl ? <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: '20px' }}
                /> : <CloseCircleTwoTone
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '20px' }}
                />
            ),
        },
        {
            title: 'G Drive',
            key: 'gdriveurl',
            dataIndex: 'gdriveurl',
            render: gdriveurl => (
                // if yturl is not empty add right icon
                gdriveurl ? <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: '20px' }}
                /> : <CloseCircleTwoTone
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '20px' }}
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        onClick={() => {
                            setEditClassFormData(record);
                            setEditSelectedClassData(record);
                            showEditDrawer();
                        }}
                    >Edit</Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getClasses();
    }, []);

    const getClasses = async () => {
        const classList = [];
        try {
            const querySnapshot = await getDocs(collection(db, "classes"));

            querySnapshot.forEach((doc) => {
                classList.push(doc.data());
            });
            setClassData(classList);
        } catch (error) {
            console.log('Error fetching users:', error);
        }
    };



    // Add class -----------------------------------------------------------------------------------
    const showAddDrawer = () => {
        setOpenAddClass(true);
    };

    const onAddClose = () => {
        setOpenAddClass(false);
    };

    const onAddClassFinish = (values) => {
        handleAddNewClass(values);
    };

    const handleAddNewClass = async (values) => {
        try {
            setIsLoading(true);

            // fill undefined values with empty string
            for (const key in values) {
                if (values[key] === undefined) {
                    values[key] = '';
                }
            }

            const name = values.name;
            const yturl = values.yturl;
            const address = values.address;
            const gdriveurl = values.gdriveurl;
            const description = values.description;

            const classId = uuidv4();
            await setDoc(doc(db, "classes", classId), {
                name: name,
                yturl: yturl,
                address: address,
                gdriveurl: gdriveurl,
                description: description,
                id: classId,
                studentsCount: 0,
            });

            setIsLoading(false);
            openNotificationWithIcon('success')
            setOpenAddClass(false);
            getClasses();
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            openNotificationWithIcon('error')
        }
    }

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: { success: 'Successfully created a class', error: 'Error' }[type],
            description: { success: 'Your new class is live now.', error: 'Something went wrong' }[type],
        });
    };



    // Edit class -----------------------------------------------------------------------------------
    const showEditDrawer = () => {
        setOpenEditClass(true);
    };

    const onEditClose = () => {
        setOpenEditClass(false);
        if (isEditing) {
            setIsEditing(false);
        }
    };

    const onEditClassFinish = (values) => {
        handleEditClass(values);
    };

    const setEditClassFormData = (record) => {
        if (record && formRef.current) {
            formRef.current.setFieldsValue({
                name: record.name,
                yturl: record.yturl,
                address: record.address,
                gdriveurl: record.gdriveurl,
                description: record.description,
            });
        }
    }

    const handleEditClass = async (values) => {
        if (!editSelectedClassData) {
            return;
        } else {
            try {
                setIsEditLoading(true);

                // fill undefined values with empty string
                for (const key in values) {
                    if (values[key] === undefined) {
                        values[key] = '';
                    }
                }

                const name = values.name;
                const yturl = values.yturl;
                const address = values.address;
                const gdriveurl = values.gdriveurl;
                const description = values.description;

                const classId = editSelectedClassData.id;

                await setDoc(doc(db, "classes", classId), {
                    name: name,
                    yturl: yturl,
                    address: address,
                    gdriveurl: gdriveurl,
                    description: description,
                    id: classId,
                    studentsCount: editSelectedClassData.studentsCount,
                });

                setIsEditLoading(false);
                openNotificationWithIconEdit('success')
                setOpenEditClass(false);
                setIsEditing(false);
                getClasses();

            } catch (error) {
                console.log(error);
                setIsEditLoading(false);
                openNotificationWithIconEdit('error')
            }
        }
    }

    const openNotificationWithIconEdit = (type) => {
        api[type]({
            message: { success: 'Successfully updated the class', error: 'Error' }[type],
            description: { success: 'Your updated class is live now.', error: 'Something went wrong' }[type],
        });
    };

    const editOrDiscard = () => {
        if (isEditing) {
            setIsEditing(false);
            setEditClassFormData(editSelectedClassData);
        } else {
            setIsEditing(true);
        }
    }



    // Delete class -----------------------------------------------------------------------------------
    const handleDeleteClass = (record) => {
        hadleDelete(record.id);
    }

    const hadleDelete = async (classId) => {
        try {
            const docRef = doc(db, "classes", classId);
            await deleteDoc(docRef);
            openNotificationWithIconDelete('success')
            setOpenEditClass(false);
            setIsEditing(false);
            getClasses();
        } catch (error) {
            console.error("Error removing document: ", error);
            openNotificationWithIconDelete('error')
            setIsEditing(false);
        }
    }

    const openNotificationWithIconDelete = (type) => {
        api[type]({
            message: { success: 'Successfully deleted the class', error: 'Error' }[type],
            description: { success: 'The deleted class is no longer availabe for students.', error: 'Something went wrong' }[type],
        });
    };

    // Render -----------------------------------------------------------------------------------

    return (
        <div className='classes'>
            {contextHolder}
            <Col span={24}
                style={{
                    textAlign: 'end',
                    marginBottom: '20px'
                }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showAddDrawer}
                >
                    Add a Class
                </Button>
            </Col>
            <Spin
                spinning={!classData}
                tip="Loading..."
            >
                {classData &&
                    <Table
                        columns={columns}
                        dataSource={classData}
                        pagination={{
                            defaultPageSize: 8,
                            showSizeChanger: true,
                            pageSizeOptions: ["8", "10", "20", "30", "50"],
                            position: ["bottomRight"]
                        }}
                        scroll={{ x: 1000 }}
                    />
                }
            </Spin>


            {/* Add class form */}
            <Drawer
                title="Add a new class"
                width={720}
                onClose={onAddClose}
                open={openAddClass}
                bodyStyle={{
                    paddingBottom: 80,
                }}
            >
                <Spin spinning={isLoading}>
                    <Form
                        layout="vertical"
                        hideRequiredMark
                        onFinish={onAddClassFinish}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="Class Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter class name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Please enter class name" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="yturl"
                                    label="Playlist URL"
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter playlist url"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter location address"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="gdriveurl"
                                    label="Google Drive Folder URL"
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please enter google drive folder url"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="Description"
                                >
                                    <Input.TextArea rows={4} placeholder="please enter the description" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        Add Class
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Drawer>


            {/* 
                Edit class form  -----------------------------------------------------------------------------------
            */}
            {editSelectedClassData &&
                <Drawer
                    title="Edit a class"
                    width={720}
                    onClose={onEditClose}
                    open={openEditClass}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                    extra={
                        <div style={{
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <Tooltip title="Reload data">
                                <Button
                                    type='default'
                                    onClick={
                                        () => {
                                            setEditClassFormData(editSelectedClassData);
                                        }
                                    }
                                >
                                    <RedoOutlined />
                                </Button>
                            </Tooltip>
                            <Button
                                type="primary"
                                onClick={
                                    () => {
                                        editOrDiscard();
                                        setEditClassFormData(editSelectedClassData);
                                    }
                                }
                            > {
                                    isEditing ? 'Discard' : 'Edit'
                                }
                            </Button>
                        </div>
                    }
                >
                    <Spin spinning={isEditLoading}>
                        <Form
                            layout="vertical"
                            hideRequiredMark
                            onFinish={onEditClassFinish}
                            ref={formRef}
                            disabled={!isEditing}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="name"
                                        label="Class Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter class name',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Please enter class name"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="yturl"
                                        label="Playlist URL"
                                    >
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please enter playlist url"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="address"
                                        label="Address"
                                    >
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please enter location address"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="gdriveurl"
                                        label="Google Drive Folder URL"
                                    >
                                        <Input
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please enter google drive folder url"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="description"
                                        label="Description"
                                    >
                                        <Input.TextArea rows={4} placeholder="please enter the description" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit">
                                            Update
                                        </Button>
                                        <Popconfirm
                                            title="Delete the class?"
                                            description="This action cannot be undone."
                                            onConfirm={
                                                () => {
                                                    handleDeleteClass(editSelectedClassData);
                                                }
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                                danger
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Drawer>}
        </div >
    )
}

export default Classes