/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Landing from './Pages/Landing'
import Signin from './Pages/Signin'
import Signup from './Pages/Signup'

import Dashboard from './Pages/Dashboard'
import Home from "./Pages/Dashboard/Home";
import Contents from "./Pages/Dashboard/Contents";
import Users from "./Pages/Dashboard/Users";
import Youtube from "./Pages/Dashboard/Youtube";
import YoutubeVideoComponent from './Pages/Dashboard/Youtube/YoutubeVideoComponent'
import Classes from './Pages/Dashboard/Classes'

import PendingPage from './Pages/404Pages/PendingPage'
import HoldPage from './Pages/404Pages/HoldPage'
import DeletedPage from './Pages/404Pages/DeletedPage'


const RoutesJs = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path="/home" element={<Landing />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />

                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/home" element={<Home />} />
                <Route path="/dashboard/contents" element={<Contents />} />
                <Route path="/dashboard/users" element={<Classes />} />
                <Route path="/dashboard/classes" element={<Users />} />
                <Route path="/dashboard/youtube" element={<Youtube />} />
                <Route path="/dashboard/youtube/video" element={<YoutubeVideoComponent />} />

                {/* 404 pages */}
                <Route path="/pending" element={<PendingPage />} />
                <Route path="/hold" element={<HoldPage />} />
                <Route path="/deleted" element={<DeletedPage />} />

            </Routes>
        </BrowserRouter>
    )
}

export default RoutesJs