/**
 * All rights reserved to Lumosys Technology Pvt. Ltd. (C) 2023
 * Written by Isuru Ariyarathna, Senal Fernando, Dinith Fernando & Maheshi Anuradha
 * 2023/09/16
 */

import React, { useEffect, useState } from "react";
import {
    DashboardOutlined,
    TeamOutlined,
    BankOutlined,
    AppstoreOutlined,
    LogoutOutlined,
    ExclamationCircleFilled,
    YoutubeOutlined,
    LoadingOutlined,
    DesktopOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Col, Row, Avatar, Space, Modal, Spin, message, Tag } from "antd";

// firebase
import { db, collection, getDocs } from '../../../firebase'
import { doc, onSnapshot } from "firebase/firestore";

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { addClassData } from "../../Common/Slices/classDataSlice";
import { setUserData } from "../../Common/Slices/userDataSlice";
import { addUsersData } from "../../Common/Slices/usersSlice";

// Images
import Lottie from 'react-lottie';
import LogoutJson from '../../../Assets/Images/logout.json'

// styles
import "./styles.sass";

// contents
import Home from "./Home";
import Contents from "./Contents";
import Users from "./Users";
import Youtube from "./Youtube";

// components
import EditUserDrawer from "./EditUserDrawer";
import Classes from "./Classes";

const { confirm } = Modal;
const { Header, Content, Footer, Sider } = Layout;

function Dashboard() {
    const [selectedNav, setSelectedNav] = useState("1");
    const [selectedNavStack, setSelectedNavStack] = useState(["1"]);
    const [user, setUser] = useState();

    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);

    const [messageApi, contextHolder] = message.useMessage();

    // Slider
    const [collapsed, setCollapsed] = useState(false);

    // handle back button
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            if (selectedNavStack.length > 1) {
                setSelectedNavStack(selectedNavStack.slice(0, -1));
                setSelectedNav(selectedNavStack[selectedNavStack.length - 2]);
            } else {
                window.history.go(1);
            }
        };
    }, [selectedNavStack]);


    // get user token
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("yasalankalmsuser"));
        if (!user) {
            window.location.href = "/signin";
        } else {
            setUser(user);
            const docRef = doc(db, "users", user.uid);
            onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    dispatch(setUserData(doc.data()));
                } else {
                    console.log("No such document!");
                }
            }
            );
            if (window.innerWidth < 900) {
                openNotification();
            }

            setInterval(() => {
            }, 2000)
        }
    }, []);

    useEffect(() => {
        // Check if user is not a student
        if (userData?.role === 'student') {
            return;
        } else {
            const getUsers = async () => {
                const userList = [];
                try {
                    const querySnapshot = await getDocs(collection(db, "users"));

                    querySnapshot.forEach((doc) => {
                        userList.push(doc.data());
                    });
                    dispatch(addUsersData(userList));
                } catch (error) {
                    console.log('Error fetching users:', error);
                }
            };
            if (userData) {
                getUsers();
            }
        }
    }, [userData]);

    // Use desktop notification
    const openNotification = () => {
        messageApi.open({
            icon: <DesktopOutlined />,
            content: 'Please use a desktop to view the dashboard',
            duration: 0,
        });
        // Dismiss manually and asynchronously
        setTimeout(messageApi.destroy, 2500);
    };

    // Edit user drawer
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

    // Navigation functions
    useEffect(() => {
        // hide the nav bar
        const nav = document.querySelector(".navContainer");
        if (nav) {
            nav.style.display = "none";
        }

        // hide the footer
        const footer = document.querySelector(".footer");
        if (footer) {
            footer.style.display = "none";
        }
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleNavClick = (navKey) => {
        setSelectedNav(navKey);
        setSelectedNavStack([...selectedNavStack, navKey]);
    };

    // Get classes of the user
    useEffect(() => {
        if (!userData) {
            return;
        }
        getAllClasses();
    }, [userData]);

    const getAllClasses = async () => {
        const classes = [];

        const querySnapshot = await getDocs(collection(db, "classes"));
        querySnapshot?.forEach((doc) => {
            classes.push(doc.data());
        });
        dispatch(addClassData(classes));
    }


    // Logout functions
    const handleLogout = () => {
        confirm({
            title: 'Are you sure you want to logout?',
            icon: <ExclamationCircleFilled />,
            content: 'Click Yes to logout',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(
                        () => {
                            resolve();
                            localStorage.removeItem("yasalankalmsuser");
                            window.location.href = "/signin";
                        }
                        , 2000
                    )
                }).catch(() => console.log('Oops errors!'));
            },
        });
    };

    // Drawer functions
    const onDrawerClose = () => {
        setIsEditProfileOpen(false);
    };

    // menu items based on user role 
    const studentsMenuItems = [
        { key: "1", icon: <DashboardOutlined />, label: "Home" },
        { key: "2", icon: <AppstoreOutlined />, label: "Contents" },
        { key: "3", icon: <YoutubeOutlined />, label: "Videos" },
        {
            key: "4", icon: <LogoutOutlined />, label: "Logout",
            onClick: handleLogout
        }
    ]

    const adminMenuItems = [
        { key: "1", icon: <DashboardOutlined />, label: "Home" },
        { key: "2", icon: <AppstoreOutlined />, label: "Contents" },
        { key: "3", icon: <TeamOutlined />, label: "Users" },
        { key: "4", icon: <BankOutlined />, label: "Classes" },
        { key: "5", icon: <YoutubeOutlined />, label: "Videos" },
        {
            key: "6", icon: <LogoutOutlined />, label: "Logout",
            onClick: handleLogout
        }
    ]

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: LogoutJson,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    function logoutPage() {
        return (
            <div className="logout-page">
                <h2>
                    You are still logged in
                </h2>
                <Lottie
                    options={lottieOptions}
                    height={300}
                    width={300}
                    style={{
                        transform: 'scale(1.3)',
                    }}
                />
            </div>
        )
    }

    const renderContents = () => {
        if (userData && userData.role === 'student') {
            return <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    height: "100%",
                    background: colorBgContainer,
                }}
            >
                {/* Render content based on the selected navigation item */}
                {selectedNav === "1" && <Home />}
                {selectedNav === "2" && <Contents />}
                {selectedNav === "3" && <Youtube />}
                {selectedNav === "4" && logoutPage()}
            </div>
        } else {
            return <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    height: "100%",
                    background: colorBgContainer,
                }}
            >
                {/* Render content based on the selected navigation item */}
                {selectedNav === "1" && <Home />}
                {selectedNav === "2" && <Contents />}
                {selectedNav === "3" && <Users />}
                {selectedNav === "4" && <Classes />}
                {selectedNav === "5" && <Youtube />}
                {selectedNav === "6" && logoutPage()}
            </div>
        }
    }




    return (
        <Layout
            style={{
                minHeight: "100vh",
            }}
        >
            {contextHolder}
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                className="adminSider"
                onCollapse={(collapsed) => setCollapsed(collapsed)}
                collapsed={collapsed}
            >
                <div className="sliderTop">
                    <div className="logo">
                        <a href='/dashboard'>
                            <h1>Yasalanka</h1>
                            <h2>Physics</h2>
                        </a>
                    </div>
                    {userData ? <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={["1"]}
                        selectedKeys={[selectedNav]}
                        onClick={({ key }) => {
                            handleNavClick(key)

                            if (window.innerWidth < 992) {
                                setCollapsed(true);
                            }

                            const tooltip = document.querySelector(".ant-tooltip");
                            if (tooltip) {
                                tooltip.style.display = "none";
                            }
                        }}

                        items={
                            userData && userData.role === 'student' ? studentsMenuItems : adminMenuItems
                        }
                        style={{ textAlign: "start" }}
                    />
                        :
                        <div
                            style={{
                                height: "500px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spin
                                size="large"
                                loading={userData}
                                indicator={<LoadingOutlined
                                    style={{
                                        fontSize: 34,
                                    }}
                                    spin
                                />}
                            />
                        </div>
                    }
                </div>
            </Sider>

            <Layout>
                <Header
                    style={{
                        height: "auto",
                        padding: 0,
                        background: colorBgContainer,
                    }}
                >
                    <Row
                        span={24}
                    >
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "0 0 0 20px",
                            }}
                        >
                            <Space
                                wrap
                                size={16}
                                style={{
                                    padding: "10px 0",
                                    marginRight: "20px",
                                }}
                            >
                                <div className="user-icon-details">
                                    {userData ? <>
                                        <div className="user-details">
                                            <h3>{userData && userData.fullname}</h3>
                                            <p>{userData && userData.email}</p>
                                        </div>
                                        <Avatar
                                            className="user-icon"
                                            size={44}
                                            src={userData && userData.photoURL ? userData.photoURL : "https://www.w3schools.com/howto/img_avatar.png"}
                                            onClick={() => setIsEditProfileOpen(true)}
                                        />
                                    </> :
                                        <Spin
                                            size="large"
                                            loading={userData}
                                            indicator={<LoadingOutlined
                                                style={{
                                                    fontSize: 34,
                                                }}
                                                spin
                                            />}
                                        />
                                    }
                                </div>
                            </Space>
                        </Col>
                    </Row>
                </Header>
                <Content
                    style={{
                        margin: "24px 16px 0",
                        overflow: "hidden",
                    }}
                    onClick={() => {
                        if (window.innerWidth < 992) {
                            if (!collapsed) {
                                setCollapsed(true);
                            }
                        }
                    }}
                >
                    {renderContents()}
                </Content>
                <Footer>
                    Yasalanka Physics ©2023 Created by <a href="http://lumosystechnologies.com/" target="_blank" rel="noreferrer">LumoSys Technologies</a>
                </Footer>

                {/* Edit User */}
                <EditUserDrawer
                    isOpen={isEditProfileOpen}
                    onClose={onDrawerClose}
                    user={user}
                />

            </Layout>
        </Layout>
    );
}

export default Dashboard;